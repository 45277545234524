import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createDetailViewRoutesMap } from './detailed-view'

export const createRoutesRouteMap = (prefix: string) => ({
  '/:id': (id = ':id') =>
    pathJoin(prefix)(id).chainMapAuto(createDetailViewRoutesMap, {
      title: <T _str="Route Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
})
