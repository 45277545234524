import { ReactNode } from 'react'
import { TOptColors } from '../SelectFilled'
import {
  TX_COMPLETED,
  TX_CREATED,
  TX_IN_PROGRESS,
  TX_NEW,
  TX_ON_HOLD,
  TX_OPEN,
  TX_PENDING,
  TX_REJECTED,
  TX_SOLVED,
} from '../../transifex'

export type TStatusType =
  | `new`
  | `open`
  | `in_progress`
  | `on_hold`
  | `solved`
  | `pending`
  | `rejected`
  | `created`
  | `completed`

export const statusTypeData: Record<
  TStatusType,
  { label: ReactNode; colors: TOptColors }
> = {
  new: {
    label: TX_NEW,
    colors: { primary: '#F38804', secondary: '#FFEDD7' },
  },
  in_progress: {
    label: TX_IN_PROGRESS,
    colors: { primary: '#1890FF', secondary: '#C1E1FF' },
  },
  on_hold: {
    label: TX_ON_HOLD,
    colors: { primary: '#F5F5F5', secondary: '#909DA9' },
  },
  open: {
    label: TX_OPEN,
    colors: { primary: '#FF4D4F', secondary: '#FFDCDC' },
  },
  solved: {
    label: TX_SOLVED,
    colors: { primary: '#7CC81B', secondary: '#F4FFB8' },
  },
  created: {
    label: TX_CREATED,
    colors: { primary: 'white', secondary: '#FFA940' },
  },
  completed: {
    label: TX_COMPLETED,
    colors: { primary: 'white', secondary: '#A0CB26' },
  },
  pending: {
    label: TX_PENDING,
    colors: { primary: '#909DA9', secondary: '#F5F5F5' },
  },
  rejected: {
    label: TX_REJECTED,
    colors: { primary: '#FFDCDC', secondary: '#FF4D4F' },
  },
}
