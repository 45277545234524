import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TDepotType =
  | 'depot'
  | 'waste_station'
  | 'workshop'
  | 'container_storage'

export const depotTypeLabelMap: Record<TDepotType, ReactNode> = {
  depot: <T _str="Depot" />,
  waste_station: <T _str="Waste Station" />,
  workshop: <T _str="Workshop" />,
  container_storage: <T _str="Container storage" />,
}

export const depotTypeOptions = createOptionsFromLabelMap(depotTypeLabelMap)
