import React, { useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/da'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/nb'
import { kebabCase } from 'lodash'
import { useMe } from '../hooks'

moment.locale('en')

/**
 * Set moment locale based on user setting.
 */
const MomentI18n = () => {
  const me = useMe()

  useEffect(() => {
    const userLocaleSetting = kebabCase(me?.datetimeFormatLocale || ``)
    if (me && userLocaleSetting) {
      // setting locale to user pref. otherwise default to en.
      if (moment.locales().includes(userLocaleSetting)) {
        moment.locale(userLocaleSetting)
      } else {
        // warn error and set to default
        // eslint-disable-next-line no-console
        console.log(
          new Error(`
          User date fomat setting: ${userLocaleSetting} is not supported by moment
        `)
        )
      }
    }
  }, [me])

  return <></>
}

export default MomentI18n
