import React from 'react'
import { TwitterPicker } from 'react-color'

export type ColorPickerProps = {
  value?: string
  onChange?: (color: string) => void
  /** Custom swatch colors */
  colors?: string[]
}

const ColorPicker = ({ value, onChange, colors }: ColorPickerProps) => {
  return (
    <TwitterPicker
      colors={
        colors ?? [
          '#000000',
          '#ffffff',
          '#FF6900',
          '#FCB900',
          '#00D084',
          '#0693E3',
          '#ABB8C3',
          '#EB144C',
          '#F78DA7',
          '#9900EF',
        ]
      }
      color={value}
      onChange={(c) => onChange?.(c.hex)}
      width="192px"
      styles={{
        default: {
          body: {
            width: 192,
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 2,
            paddingBottom: 2,
          },
          swatch: {
            width: 20,
            height: 20,
            boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 4px',
          },
          input: {
            height: 20,
            width: 58,
          },
          label: {
            height: 20,
          },
          hash: {
            height: 22,
          },
        },
      }}
      triangle="hide"
    />
  )
}

export { ColorPicker }
