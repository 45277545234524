import moment, { Moment } from 'moment'
import { ReactNode } from 'react'
import {
  TX_CUSTOM,
  TX_NONE,
  TX_PAST_YEAR,
  TX_THIS_MONTH,
  TX_THIS_WEEK,
  TX_THIS_YEAR,
  TX_TODAY,
  TxPastDaysNum,
  TxPastMonthsNum,
} from '../../transifex'

export type TQuickDateOption =
  | 'today'
  | 'this_week'
  | 'past_7_days'
  | 'this_month'
  | 'last_30_days'
  | 'past_3_months'
  | 'past_6_months'
  | 'past_year'
  | 'this_year'
  | 'custom'
  | 'none'

export const quickDateOptionsLabelMap: Record<TQuickDateOption, ReactNode> = {
  today: TX_TODAY,
  this_week: TX_THIS_WEEK,
  past_7_days: TxPastDaysNum(7),
  this_month: TX_THIS_MONTH,
  last_30_days: TxPastDaysNum(30),
  past_3_months: TxPastMonthsNum(3),
  past_6_months: TxPastMonthsNum(6),
  past_year: TX_PAST_YEAR,
  this_year: TX_THIS_YEAR,
  custom: TX_CUSTOM,
  none: TX_NONE,
}

export const quickDateOptions = Object.keys(quickDateOptionsLabelMap).map(
  (key) => ({
    label: quickDateOptionsLabelMap[key as TQuickDateOption],
    value: key,
  })
)

export const quickDateOptionMap: Record<
  TQuickDateOption,
  () => [Moment, Moment] | null
> = {
  today: () => [moment().startOf('day'), moment().endOf('day')],
  this_week: () => [moment().startOf('week'), moment().endOf('week')],
  past_7_days: () => [
    moment().subtract(7, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day'),
  ],
  this_month: () => [moment().startOf('month'), moment().endOf('month')],
  last_30_days: () => [
    moment().subtract(30, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day'),
  ],
  past_3_months: () => [
    moment().subtract(3, 'months').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ],
  past_6_months: () => [
    moment().subtract(6, 'months').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ],
  past_year: () => [
    moment().subtract(1, 'year').startOf('year'),
    moment().subtract(1, 'year').endOf('year'),
  ],
  this_year: () => [moment().startOf('year'), moment().endOf('year')],
  custom: () => [moment().subtract(1, 'day'), moment().add(1, 'day')],
  none: () => null,
}

export const getQuickDateSelectValue = (
  value?: [Moment, Moment]
): TQuickDateOption => {
  if (!value) {
    return 'none'
    // eslint-disable-next-line no-else-return
  } else {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in quickDateOptionsLabelMap) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const date = quickDateOptionMap[key as TQuickDateOption]()
      if (
        date?.[0].isSame(value[0], 'date') &&
        date?.[1].isSame(value[1], 'date')
      ) {
        return key as TQuickDateOption
      }
    }
  }

  return 'custom'
}
