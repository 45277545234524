import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createVehicleRouteMap } from './detailed-view'
import { createRouteMapVehicle } from './create'

export const createRoutesMapVehicleManagementRouter = (prefix: string) => ({
  ':vehicleId': (vehicleId: string) =>
    pathJoin(prefix)(vehicleId).chainMapAuto(createVehicleRouteMap, {
      title: <T _str="Vehicle Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
  create: pathJoin(prefix)('create').chainMapAuto(createRouteMapVehicle, {
    title: <T _str="New Vehicle" />,
    breadCrumbTitle: <T _str="New" />,
  }),
})
