import React from 'react'
import ReactDOM from 'react-dom'
import 'react-toastify/dist/ReactToastify.css'
import 'animate.css'
import 'antd/dist/antd.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'typeface-roboto'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import client from './components/graphql/apollo'
import store from './store'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './setupTransifex'
import './fonts.css'
import { ErrorBoundary } from './setupSentry'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.mapstyles = process.env.REACT_APP_MAPBOX_STYLES

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ApolloProvider>
  </Provider>,
  document.getElementById('main')
)
serviceWorker.unregister()
