import Cookies from 'universal-cookie'

const cookies = new Cookies()

export { cookies }

export const COOKIES_CONST = {
  API_URL: 'apiCookie',
  ENV: {
    API_URL_VALUE: process.env.REACT_APP_API_COOKIE_URL,
  },
}
