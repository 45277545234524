import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdBatteryFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.00768 5.18841C6.00768 5.17947 6.00768 5.17138 6.00768 5.16328C6.00768 5.15604 6.00768 5.14923 6.00768 5.14199V5.11559C6.00723 4.83877 6.00632 4.26342 6.42389 3.74726L7.69511 2.10254V1.38622C6.63719 1.26017 4.67319 1.2904 3.84439 1.2904C0.166303 1.2904 0.0736612 1.11452 0.0714017 1.10259L0.23906 4.26256C0.337124 5.12496 0.488062 6.01205 0.974768 6.74029C1.02086 6.80885 1.07148 6.8821 1.06876 6.96387C1.0665 7.02818 1.03171 7.0861 1.00053 7.14274C0.704526 7.67295 0.631317 8.26576 0.563079 8.86496C0.514273 9.29467 0.465466 9.72395 0.416208 10.1537C0.375989 10.5063 0.377344 10.7418 0.337124 11.094C0.333961 11.2251 0.2011 11.338 0.117045 11.4406C0.0329895 11.5433 0.00723054 11.7741 0 11.9619C0.173533 12.0241 1.29517 11.9985 2.24418 11.967C2.84883 11.947 3.39338 11.8789 3.54387 11.8614C4.37041 11.7664 5.22678 11.7498 6.08179 11.7652C6.03298 11.6732 6.00723 11.5735 6.00723 11.4713L6.00768 5.18841ZM5.83369 2.72176C5.83369 2.93086 5.65203 3.10206 5.43014 3.10206H2.77834C2.55645 3.10206 2.37478 2.93086 2.37478 2.72176C2.37478 2.51266 2.55645 2.34146 2.77834 2.34146H5.43014C5.65203 2.34146 5.83369 2.51266 5.83369 2.72176Z" />
      <path d="M6.2368 5.17992C6.2368 5.16374 6.23725 5.14968 6.23725 5.13393C6.23725 4.95719 6.23092 4.65439 6.34706 4.32519C6.23183 4.63778 6.23228 4.93036 6.23228 5.11519V5.14202C6.23228 5.15054 6.23228 5.15863 6.23228 5.16672C6.23228 5.17396 6.23228 5.1812 6.23228 5.18886V11.4713C6.23228 11.5769 6.27611 11.6834 6.35384 11.7711C6.28109 11.6898 6.23725 11.5863 6.23725 11.4743L6.2368 5.17992Z" />
      <path d="M11.4704 3.73362L9.64736 2.1745V0.00468429L7.91971 0V2.17152L7.91833 2.17411L7.87224 2.23288L6.60285 3.87458C6.48264 4.02321 6.4013 4.17653 6.34617 4.32558C6.23003 4.65478 6.23635 4.958 6.23635 5.13431C6.23635 5.15007 6.2359 5.16412 6.2359 5.18031V11.4743C6.2359 11.5867 6.27974 11.6898 6.35295 11.7711C6.45146 11.881 6.60285 11.9512 6.77277 11.9512H11.4631C11.7596 11.9512 12 11.7375 12 11.4743V5.16455C12 5.14496 12 5.12622 12 5.10621C12 4.77956 11.9643 4.16886 11.4695 3.73362H11.4704ZM11.1866 7.68358C11.1866 7.89183 11.0058 8.06218 10.7848 8.06218H10.7817C10.5607 8.06218 10.3799 7.89183 10.3799 7.68358V5.18158C10.3799 4.97333 10.5607 4.80298 10.7817 4.80298H10.7848C11.0058 4.80298 11.1866 4.97333 11.1866 5.18158V7.68358Z" />
    </svg>
  )
})

export default WhIconAntdBatteryFilled
