import { pathJoin } from '../../../../utils'

export const createDetailRoutesMap = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chain,
  'collection-log': pathJoin(prefix)('collection-log').chain,
  'event-log': pathJoin(prefix)('event-log').chain,
  tickets: pathJoin(prefix)('tickets').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  overview: pathJoin(prefix)('overview').chain,
  calendar: pathJoin(prefix)('calendar').chain,
  subscriptions: pathJoin(prefix)('subscriptions').chain,
  'export-runs': pathJoin(prefix)('export-runs').chain,
  customers: pathJoin(prefix)('customers').chain,
  agreements: pathJoin(prefix)('agreements').chain,
})
