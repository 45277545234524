import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createFeeMap } from './[fee]'

export const createCompanyCrmPricingRoutesMap = (prefix: string) => ({
  'new-container-product': pathJoin(prefix)(
    'new-container-product'
  ).chainWithTitle({
    title: <T _str="New Container Product" />,
    breadCrumbTitle: <T _str="New Container Product" />,
  }),
  'new-service-product': pathJoin(prefix)('new-service-product').chainWithTitle(
    {
      title: <T _str="New Service Product" />,
      breadCrumbTitle: <T _str="New Service Product" />,
    }
  ),
  'new-recurring-product': pathJoin(prefix)(
    'new-recurring-product'
  ).chainWithTitle({
    title: <T _str="New Recurring Product" />,
    breadCrumbTitle: <T _str="New Recurring Product" />,
  }),
  'container-fee/:id': (id: string) =>
    pathJoin(prefix)('container-fee')(id).chainMapAuto(createFeeMap, {
      title: <T _str="Container Product Details" />,
      breadCrumbTitle: <T _str="Container Product Details" />,
    }),
  'service-fee/:id': (id: string) =>
    pathJoin(prefix)('service-fee')(id).chainMapAuto(createFeeMap, {
      title: <T _str="Service Product Details" />,
      breadCrumbTitle: <T _str="Service Product Details" />,
    }),
  'recurring-fee/:id': (id: string) =>
    pathJoin(prefix)('recurring-fee')(id).chainMapAuto(createFeeMap, {
      title: <T _str="Recurring Product Details" />,
      breadCrumbTitle: <T _str="Recurring Product Details" />,
    }),
})
