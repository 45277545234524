import { gql, useQuery } from '@apollo/client'
import { useMe } from './useMe'

const GET_ME_META_VALUES_CAN_EDIT = gql`
  query FormPropertyGetMetaValuesCanEdit($id: ID!) {
    user(id: $id) {
      id
      metaTypesCanEdit {
        id
        name
        entity
      }
    }
  }
`

export const useMeMetaTypesCanEdit = () => {
  const { id } = useMe()
  const { data, ...r } = useQuery(GET_ME_META_VALUES_CAN_EDIT, {
    variables: { id },
  })

  const meMetaTypesCanEdit = data?.user?.metaTypesCanEdit

  return { data: meMetaTypesCanEdit, ...r }
}
