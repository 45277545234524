import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createContainersRouteMap } from './container'
import { createPropertiesRouteMap } from './properties'

export const createCreateBulkRoutesMap = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chainMapAuto(
    createContainersRouteMap,
    {
      title: <T _str="Create Bulk Container Tickets" />,
      breadCrumbTitle: <T _str="Create Bulk Container Tickets" />,
    }
  ),
  properties: pathJoin(prefix)('properties').chainMapAuto(
    createPropertiesRouteMap,
    {
      title: <T _str="Create Bulk Property Tickets" />,
      breadCrumbTitle: <T _str="Create Bulk Property Tickets" />,
    }
  ),
})
