import { FontSizeOutlined, MoreOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { ContentUtils } from 'braft-utils'
import { Col, Popover, PopoverProps, Row } from 'antd'
import React from 'react'
import { ContentProps } from '../types'

import FontSize from '../Components/FontSize'
import Divider from '../Components/Divider'
import Alignment from '../Components/Alignment'
import TextColor from '../Components/TextColor'
import BackgroundColor from '../Components/BackgroundColor'
import InlineStyle from '../Components/InlineStyle'
import Block from '../Components/BlockType'
import ControlBarButton from '../Components/ControlBarButton'
import Emoji from '../Components/Emoji'

const PopoverWithOverlayClassname = ({
  className,
  ...props
}: PopoverProps & { className?: string }) => (
  <Popover {...props} overlayClassName={className} />
)

const StyledPopover = styled(PopoverWithOverlayClassname)`
  .ant-popover-inner-content {
    padding: 6px 8px;
  }
`

const LeftContent = ({ editorState, setEditorState, wide }: ContentProps) => {
  const collapsibleControls = (
    <Row gutter={8} align="middle">
      <Col>
        <Alignment
          editorState={editorState}
          onAlign={(alignment) => {
            setEditorState(
              ContentUtils.toggleSelectionAlignment(editorState, alignment)
            )
          }}
        />
      </Col>
      <Divider />
      <Col>
        <TextColor
          onColorChange={(newColor) => {
            setEditorState(
              ContentUtils.toggleSelectionColor(editorState, newColor)
            )
          }}
          editorState={editorState}
        />
      </Col>
      <Col>
        <BackgroundColor
          onColorChange={(newColor) => {
            setEditorState(
              ContentUtils.toggleSelectionBackgroundColor(editorState, newColor)
            )
          }}
          editorState={editorState}
        />
      </Col>
      <Divider />
      <Col>
        <InlineStyle
          editorState={editorState}
          toggleInlineStyle={(style) => {
            setEditorState(
              ContentUtils.toggleSelectionInlineStyle(editorState, style)
            )
          }}
        />
      </Col>
      <Divider />
      <Col>
        <Block
          editorState={editorState}
          toggleBlock={(block) => {
            setEditorState(
              ContentUtils.toggleSelectionBlockType(editorState, block)
            )
          }}
        />
      </Col>
      <Divider />
      <Col>
        <Emoji
          onEmojiSelect={(emoji) =>
            setEditorState(ContentUtils.insertText(editorState, emoji))
          }
        />
      </Col>
    </Row>
  )

  return (
    <Row gutter={8} align="middle">
      <Col>
        <FontSizeOutlined />
      </Col>
      <Col>
        <FontSize
          editorState={editorState}
          onFontSizeChange={(fontSize) => {
            setEditorState(
              ContentUtils.toggleSelectionFontSize(editorState, fontSize)
            )
          }}
        />
      </Col>
      {wide && (
        <>
          <Divider />
          <Col>{collapsibleControls}</Col>
        </>
      )}
      {!wide && (
        <Col>
          <StyledPopover trigger={['click']} content={collapsibleControls}>
            <ControlBarButton icon={<MoreOutlined />} />
          </StyledPopover>
        </Col>
      )}
    </Row>
  )
}

export default LeftContent
