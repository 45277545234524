import React from 'react'
import { T, useT, UT } from '@transifex/react'

export const UTX_LAST = <UT _str="Last" _inline />
export const UTX_NEXT = <UT _str="Next" _inline />

export const TX_LOWERCASE_MONTH = <T _str="month" />
export const TX_LOWERCASE_YEAR = <T _str="year" />
export const TX_BETA = <T _str="Beta" />
export const TX_ADD = <T _str="Add" />
export const TX_HR = <T _str="hr" />
export const TX_MIN = <T _str="min" />
export const TX_SEC = <T _str="s" />
export const TX_RATING = <T _str="Rating" />
export const TX_DISTANCE = <T _str="Distance" />
export const TX_COLLECTED_WEIGHT = <T _str="Collected weight" />
export const TX_VOLUME = <T _str="Volume" />
export const TX_DURATION = <T _str="Duration" />
export const TX_COMPLETED_AT = <T _str="Completed at" />
export const TX_STARTED_AT = <T _str="Started at" />
export const TX_END_TIME = <T _str="End time" />
export const TX_START_TIME = <T _str="Start time" />
export const TX_GOOGLE_MAPS_STREET = <T _str="Google maps street view" />
export const TX_GOOGLE_MAPS = <T _str="Google maps" />
export const TX_PICKUP_TIMES = <T _str="Pickup times" />
export const TX_CONTAINER_ID = <T _str="Container ID" />
export const TX_LOADING = <T _str="loading..." />
export const TX_STOPS_ALERT_MESSAGE = (
  <T _str="You haven't added $TSFixMe stops - you can manually add containers or inquiries from the tabs above." />
)
export const TX_ADD_STOPS = <T _str="Add stops" />
export const TX_OPTIMIZE = <T _str="Optimize" />
export const TX_SETUP = <T _str="Setup" />
export const TX_SHOW_MORE_FILTERS = <T _str="Show more filters" />
export const TX_HIDE = <T _str="Hide" />
export const TX_STSD = <T _str="stsd" />
export const TX_EXPAND = <T _str="Expand" />
export const TX_CUSTOM = <T _str="Custom" />
export const TX_ATTACHMENTS = <T _str="Attachments" />
export const TX_OPTIONAL = <T _str="optional" />
export const TX_PREVIEW = <T _str="Preview" />
export const TX_UPLOAD = <T _str="Upload" />
export const TX_DISPLAY_COLUMNS = <T _str="Display columns" />
export const TX_INVITED = <T _str="Invited" />
export const TX_OFF_ROUTE = <T _str="Off route" />
export const TX_ON_ROUTE = <T _str="On route" />
export const TX_SERVICE = <T _str="Service" />
export const TX_FLEET_MANAGER = <T _str="Fleet manager" />
export const TX_ROUTE_PLANNER = <T _str="Route planner" />
export const TX_DRIVER = <T _str="Driver" />
export const TX_ADMINISTRATION = <T _str="Administration" />
export const TX_ACCOUNT_MANAGER = <T _str="Account manager" />
export const TX_ACTIVE = <T _str="Active" />
export const TX_ACCEPTED = <T _str="Accepted" />
export const TX_ACCEPTED_ADD_TO_ROUTE = <T _str="Accepted: Add to route" />
export const TX_LOW = <T _str="Low" />
export const TX_MEDIUM = <T _str="Medium" />
export const TX_HIGH = <T _str="High" />
export const TX_CRITICAL = <T _str="Critical" />
export const TX_OFF_WORK = <T _str="Off work" />
export const TX_UNAVAILABLE = <T _str="Unavailable" />
export const TX_WORKING = <T _str="Working" />
export const TX_DEFECT = <T _str="Defect" />
export const TX_AVAILABLE = <T _str="Available" />
export const TX_REGULAR_USER = <T _str="Regular User" />
export const TX_WASTEHERO = <T _str="WasteHero" />
export const TX_PROJECT_OWNER = <T _str="Project Owner" />
export const TX_ADMIN = <T _str="Admin" />
export const TX_DYNAMIC = <T _str="Dynamic" />
export const TX_STATIC = <T _str="Static" />
export const TX_NONE = <T _str="None" />
export const TX_RESEND_INVITATION = <T _str="Resend Invitation" />
export const TX_CONFIRM = <T _str="Confirm" />
export const TX_SELECT_ALL = <T _str="Select All" />
export const TX_CLEAR = <T _str="Clear" />
export const TX_NOTIFICATIONS = <T _str="Notifications" />
export const TX_PROJECTS = <T _str="Projects" />
export const TX_HEAT_MAPS = <T _str="Heat maps" />
export const TX_OVERVIEW = <T _str="Overview" />
export const TX_CREATE_ACCOUNT = <T _str="Create account" />
export const TX_USER_MANAGEMENT = <T _str="User management" />
export const TX_LOCATIONS = <T _str="Locations" />
export const TX_DRIVER_MANAGEMENT = <T _str="Driver management" />
export const TX_VEHICLES = <T _str="Vehicles" />
export const TX_FLEET_MANAGEMENT = <T _str="Fleet management" />
export const TX_PICKUP_HISTORY = <T _str="Pickup History" />
export const TX_ROUTE_SCHEMES = <T _str="Route Schemes" />
export const TX_PICKUP_SETTINGS = <T _str="Pickup settings" />
export const TX_INQUIRIES = <T _str="Inquiries" />
export const TX_OPERATION_MANAGEMENT = <T _str="Operation management" />
export const TX_ADD_SENSOR = <T _str="Add sensor" />
export const TX_ATTACH_SENSOR = <T _str="Attach sensor" />
export const TX_DASHBOARD = <T _str="Dashboard" />
export const TX_REJECTED = <T _str="Rejected" />
export const TX_PENDING = <T _str="Pending" />
export const TX_SOLVED = <T _str="Solved" />
export const TX_OPEN = <T _str="Open" />
export const TX_ON_HOLD = <T _str="On-hold" />
export const TX_IN_PROGRESS = <T _str="In progress" />
export const TX_NEW = <T _str="New" />
export const TX_NO_DATA = <T _str="No data" />
export const TX_INSERT_VARIABLES = <T _str="Insert variables" />
export const TX_TEXT_COLOR = <T _str="Text color" />
export const TX_UNDERLINE = <T _str="Underline" />
export const TX_STRIKE_THROUGH = <T _str="Strike through" />
export const TX_ITALIC = <T _str="Italic" />
export const TX_BOLD = <T _str="Bold" />
export const TX_SIZE_32 = <T _str="Heading - 32 px" />
export const TX_SIZE_24 = <T _str="Subheading - 24 px" />
export const TX_SIZE_16 = <T _str="Body - 16 px" />
export const TX_SIZE_12 = <T _str="Text - 12 px" />
export const TX_EMOJI = <T _str="Emoji" />
export const TX_BULLET_LIST = <T _str="Bullet list" />
export const TX_NUMBER_LIST = <T _str="Number list" />
export const TX_BACKGROUND_COLOR = <T _str="Background color" />
export const TX_RIGHT_ALIGN = <T _str="Right align" />
export const TX_CENTER_ALIGN = <T _str="Center align" />
export const TX_LEFT_ALIGN = <T _str="Left align" />
export const TX_TEXT_EDITOR_IS_IN_FULLSCREEN_MODE = (
  <T _str="Text editor is in fullscreen mode." />
)
export const TX_ALL_YEAR = <T _str="All Year" />
export const TX_ALL_MONTH = <T _str="All Month" />
export const TX_ALL_WEEK = <T _str="All Week" />
export const TX_ALL_DAY = <T _str="All Day" />
export const TX_CHANGE_VEHICLE = <T _str="Change vehicle" />
export const TX_CHANGE_DRIVER = <T _str="Change Driver" />
export const TX_ERROR = <T _str="Error" />
export const TX_ROW = <T _str="Row" />
export const TX_CREATE_EXPORT = <T _str="Create Export" />
export const TX_YOU_ARE_ABOUT_TO_DELETE_A = (
  <T _str="You are about to delete a" />
)
export const TX_WHICH_CANT_BE_UNDONE = <T _str="which can't be undone." />
export const TX_DELETE = <T _str="Delete" />
export const TX_EDIT = <T _str="Edit" />
export const TX_THIS_ACTION_IS_IRREVERSIBLE = (
  <T _str="This action is irreversible." />
)
export const TX_USER_NAME = <T _str="User name" />
export const TX_WASTE_STATION = <T _str="Waste station" />
export const TX_TICKET_TYPE = <T _str="Ticket type:" />
export const TX_CREATED_BY = <T _str="Created by" />
export const TX_CREATED_DATE = <T _str="Created Date" />
export const TX_INQUIRY_TYPE = <T _str="Inquiry type" />
export const TX_STATUS_PRIORITY = <T _str="Status / Priority" />
export const TX_STOPS = <T _str="Stops" />
export const TX_LOCATION = <T _str="Location" />
export const TX_SCHEDULED = <T _str="Scheduled" />
export const TX_INQUIRIES_INFORMATION = <T _str="Inquiries Information" />
export const TX_SEARCH_REQUEST_FAILED = <T _str="Search request failed." />
export const TX_VIEW = <T _str="View" />
export const TX_CONTAINER_TYPE = <T _str="Container Type" />
export const TX_APPLY = <T _str="Apply" />
export const TX_CANCEL = <T _str="Cancel" />
export const TX_REMOVE = <T _str="Remove" />
export const TX_RESET = <T _str="Reset" />
export const TX_TO = <T _str="To" />
export const TX_FROM = <T _str="From" />
export const TX_START_DATE = <T _str="Start date" />
export const TX_END_DATE = <T _str="End date" />
export const TX_NO_END_DATE = <T _str="No end date" />
export const TX_NO_START_DATE = <T _str="No start date" />
export const TX_ROUTE_INFORMATION = <T _str="Route information" />
export const TX_SCHEDULE = <T _str="Schedule" />
export const TX_MONTHLY = <T _str="Monthly" />
export const TX_WEEKLY = <T _str="Weekly" />
export const TX_DAILY = <T _str="Daily" />
export const TX_ONE_TIME = <T _str="One-time" />
export const TX_RECURRING = <T _str="Recurring" />
export const TX_CONSENT = (
  <T _str="I hereby consent to share essential information and to be contacted by WasteHero expert." />
)
export const TX_SHORTLY_EXPLAIN = (
  <T _str="Shortly explain your interest in WasteHero (optional)" />
)
export const TX_COUNTRY = <T _str="Country" />
export const TX_WORK_EMAIL = <T _str="Work email" />
export const TX_CONFIRM_PASSWORD = <T _str="Confirm password" />
export const TX_PASSWORD = <T _str="Password" />
export const TX_FULL_NAME = <T _str="Full name" />
export const TX_EXISTING_ROUTE = <T _str="Existing route" />
export const TX_DATE = <T _str="Date" />
export const TX_SUBMIT = <T _str="Submit" />
export const TX_SUBMITTING = <T _str="Submitting..." />
export const TX_SHOULD_THE_PHYSICAL_LOCATION_BE_UPDATED = (
  <T _str="Should the physical location of the container be updated?" />
)
export const TX_SHOULD_WE_UPDATE_CONTAINERS = (
  <T _str="Should we update containers based on the file?" />
)
export const TX_SHOULD_WE_CREATE_NEW_CONTAINERS = (
  <T _str="Should we create new containers based on the file?" />
)
export const TX_DESCRIPTION_HELP_TEXT = (
  <T _str="Use this field to add description to a container. Optional when creating, optional when updating." />
)

export const TX_PICKUP_SETTING_ID_HELP_TEXT = (
  <T _str="This id describes what pickup setting the container should be created with. Optional when creating, optional when updating." />
)

export const TX_LOCATION_HELP_TEXT = (
  <T _str="You can choose to use 'location_name' which should be the address with country (eg. 'Europaplads 16, 8000 Aarhus, Denmark'), or you can choose to use 'location_latitude' and 'location_longitude' (or 'location_latitude_longitude' with comma separation). Required when creating, optional when updating." />
)
export const TX_WASTE_FRACTION_ID_HELP_TEXT = (
  <T _str="This id describes what waste fraction the container should be created with. Required when creating, optional when updating." />
)
export const TX_CONTAINER_TYPE_ID_HELP_TEXT = (
  <T _str="This id describes what container type the container should be created with. Required when creating, optional when updating." />
)
export const TX_CONTAINER_ID_HELP_TEXT = (
  <T _str="This id describes what container id the container should be created with. Required when creating, required when updating." />
)
export const TX_COLUMNS = <T _str="Columns" />
export const TX_EXAMPLE_FILES = <T _str="Example files" />
export const TX_IMPORT_DESC_1 = <T _str="The Import Example File" />
export const TX_IMPORT_DESC_3 = <T _str="The Import Type Ids File" />
export const TX_IMPORT_DESC_4 = (
  <T _str="has all the ids that the columns specified below require." />
)
export const TX_IMPORT_DESC_2 = (
  <T _str="is a basic example of how to import containers with the least required columns." />
)
export const TX_OR = <T _str="or" />
export const TX_EXCEL = <T _str="Excel" />
export const TX_CSV = <T _str="CSV" />
export const TX_HOW_TO_SETUP_FILE = <T _str="How to setup the file" />
export const TX_ADD_DRIVER_SKILL = <T _str="Add driver skill" />
export const TX_NO_DRIVER_SKILLS_ADDED = (
  <T _str="No driver's skills has been added." />
)
export const TX_LICENSE_NUMBER = <T _str="License number" />
export const TX_VALID_FROM = <T _str="Valid from" />
export const TX_COST_PER_OVERTIME_HOUR = <T _str="Cost per overtime hour" />
export const TX_COST_PER_INTERVAL = <T _str="Cost per interval" />
export const TX_COST_PER_HOUR = <T _str="Cost per hour" />
export const TX_TYPE_OF_COST = <T _str="Type of cost" />
export const TX_WORK_TIME = <T _str="Work time" />
export const TX_EMPLOYEE_NUMBER = <T _str="Employee number" />
export const TX_VARIABLE = <T _str="Variable" />
export const TX_FIXED = <T _str="Fixed" />
export const TX_COMPANY_DESCRIPTION_OPTIONAL = (
  <T _str="Comp$TSFixMe description (optional)" />
)
export const TX_COMPANY_NAME = <T _str="Comp$TSFixMe name" />
export const TX_SUPPORT_FOR_A_SINGLE_OR_BULK_UPLOAD = (
  <T _str="Support for a single or bulk upload. Strictly prohibited from uploading comp$TSFixMe data or other band files" />
)
export const TX_CLICK_OR_DRAG_FILE_TO_THIS_AREA_TO_UPLOAD = (
  <T _str="Click or drag file to this area to upload" />
)
export const TX_SEND = <T _str="Send" />
export const TX_PHONE = <T _str="Phone" />
export const TX_LONGITUDE = <T _str="Longitude" />
export const TX_LATITUDE = <T _str="Latitude" />
export const TX_SHOW_LAT_LONG = <T _str="Show advance" />
export const TX_HIDE_LAT_LONG = <T _str="Hide" />
export const TX_MANUAL = <T _str="Manual" />
export const TX_SEARCH = <T _str="Search" />
export const TX_NOT_DISABLED = <T _str="not-disabled" />
export const TX_DISABLED = <T _str="disabled" />
export const TX_SUMMARY = <T _str="Summary" />
export const TX_CONTAINERS = <T _str="Containers" />
export const TX_DEPOTS = <T _str="Depots" />
export const TX_WASTE_STATIONS = <T _str="Waste stations" />
export const TX_DRAWER_WITH_ASYNC_TABS = <T _str="Drawer with Aync tabs" />
export const TX_ROUTESCHEMECONTENT = <T _str="RouteSchemeContent" />
export const TX_VEHICLE = <T _str="Vehicle" />
export const TX_DRIVERS = <T _str="Drivers" />
export const TX_MAX_VOLUME = <T _str="Max volume:" />
export const TX_MAX_WEIGHT = <T _str="Max weight:" />
export const TX_USER = <T _str="User" />
export const TX_LANGUAGE = <T _str="Language" />
export const TX_TITLE = <T _str="Title" />
export const TX_PHONE_NUMBER = <T _str="Phone Number" />
export const TX_EMAIL = <T _str="E-mail" />
export const TX_USERNAME = <T _str="Username" />
export const TX_ROUTES = <T _str="Routes" />
export const TX_ROUTE_STOP_COUNT = <T _str="Route stop count" />
export const TX_VEHICLE_NAME = <T _str="Vehicle name" />
export const TX_VEHICLE_ID = <T _str="Vehicle Id" />
export const TX_PROJECT_ID = <T _str="Project Id" />
export const TX_DRIVER_NAME = <T _str="Driver name" />
export const TX_DRIVER_EMAIL = <T _str="Driver e-mail" />
export const TX_DAYS = <T _str="Days" />
export const TX_DELETE_FILE = <T _str="Delete File" />
export const TX_CONFIRM_DELETE_FILE = (
  <T _str="You are about to delete a File which can't be undone." />
)
export const TX_WEEKS_BETWEEN = <T _str="Weeks between" />
export const TX_COLLECTIONS_PER_WEEK = <T _str="Collections per week" />
export const TX_FIRST_COLLECTION = <T _str="First Collection" />
export const TX_NAME = <T _str="Name" />
export const TX_INQUIRY = <T _str="Inquiry" />
export const TX_NO_PICTURES_FOUND = <T _str="No pictures found" />
export const TX_IMAGES = <T _str="Images" />
export const TX_ESTIMATED_WASTE_WEIGHT = <T _str="Estimated waste weight" />
export const TX_ESTIMATED_WASTE_VOLUME = <T _str="Estimated waste volume" />
export const TX_ESTIMATIONS = <T _str="Estimations" />
export const TX_DESCRIPTION = <T _str="Description" />
export const TX_DEADLINE = <T _str="Deadline" />
export const TX_CREATED_AT = <T _str="Created At" />
export const TX_REPORTER = <T _str="Reporter" />
export const TX_TYPE = <T _str="Type" />
export const TX_INFO = <T _str="Info" />
export const TX_PRIORITY = <T _str="Priority" />
export const TX_IMPORT_CONTAINER_WEIGHT = <T _str="Import Container Weight" />
export const TX_ERRORS = <T _str="Errors" />
export const TX_DOWNLOAD = <T _str="Download" />
export const TX_FILE = <T _str="File" />
export const TX_DELIMITER = <T _str="Delimiter" />
export const TX_STATUS = <T _str="Status" />
export const TX_COMPLETED = <T _str="Completed" />
export const TX_CREATED = <T _str="Created" />
export const TX_CREATE = <T _str="Create" />
export const TX_PROJECT = <T _str="Project" />
export const TX_IMPORT_CONTAINERS = <T _str="Import Containers" />
export const TX_DEPOT = <T _str="Depot" />
export const TX_TOTAL_TRUCKS = <T _str="Total trucks" />
export const TX_DEPOT_STATUS = <T _str="Depot status" />
export const TX_DEPOT_OPENING_TIMES = <T _str="Depot opening times" />
export const TX_CONTACT_PERSON_EMAIL = <T _str="Contact person email" />
export const TX_CONTACT_PERSON_NAME = <T _str="Contact person name" />
export const TX_DEPOT_TYPE = <T _str="Depot type" />
export const TX_DEPOT_NAME = <T _str="Depot name" />
export const TX_CONTAINER = <T _str="Container" />
export const TX_WEIGHT = <T _str="Weight" />
export const TX_ID = <T _str="ID" />
export const TX_CONTAINER_NAME = <T _str="Container name" />
export const TX_ADDRESS = <T _str="Address" />
export const TX_SENSOR = <T _str="Sensor" />
export const TX_PICKUP_SETTING = <T _str="Pickup setting" />
export const TX_WASTE_FRACTION = <T _str="Waste fraction" />
export const TX_FILL_LEVEL = <T _str="Fill level" />

export const TxPastDaysNum = (num: number) => (
  <T _str="Past {num} days" num={num} />
)

export const TxPastMonthsNum = (num: number) => (
  <T _str="Past {num} months" num={num} />
)

export const TX_TODAY = <T _str="Today" />
export const TX_THIS_WEEK = <T _str="This week" />
export const TX_THIS_MONTH = <T _str="This month" />
export const TX_PAST_YEAR = <T _str="Past year" />
export const TX_THIS_YEAR = <T _str="This year" />

export const TX_MUNICIPALITY = <T _str="Municipality code" />
export const TX_POSTAL_CODE = <T _str="Postal code" />
export const TX_POSTAL_CODE_NAME = <T _str="Postal code name" />
export const TX_STREET = <T _str="Street" />
export const TX_STREET_NUMBER = <T _str="Street number" />

export const useTxStrings = () => {
  const t = useT()

  const txLoading = () => t('Loading...')
  const txRemove = () => t('Remove')
  const txCancel = () => t('Cancel')
  const txConfirm = () => t('Confirm')
  const txLongitude = () => t('Longitude')
  const txLatitude = () => t('Latitude')
  const txEdit = () => t('Edit')
  const txRequiredField = () => t('Required field.')
  const txSubmit = () => t('Submit')
  const txComp$TSFixMeName = () => t('Comp$TSFixMe name')
  const txComments = () => t('Comments')
  const txShowContainers = () => t('Show containers')
  const txShowDepots = () => t('Show depots')
  const txFilter = () => t('Filter')
  const txSave = () => t('Save')
  const txEg123 = () => t('e.g. abc123')
  const txEg2$ = () => t('e.g. 2$')
  const txUploadText = () => t('Click or drag a .csv file')
  const txShouldCreate = () => t('Allow creating new containers')
  const txShouldUpdate = () => t('Allow updating existing containers')
  const txShouldUpdateGeoLocation = () =>
    t('Allow updating container locations')
  const txUploadHint = () => t('The file needs to be in utf-8.')
  const txFullName = () => t('Full name')
  const txPhoneNumber = () => t('Phone Number')
  const txPassword = () => t('Password')
  const txConfirmPassword = () => t('Confirm password')
  const txCountry = () => t('Country')
  const txWorkEmail = () => t('Work email')
  const txSend = () => t('Send')
  const txEmail = () => t('email')
  const txResetPassword = () => t('Reset password')
  const txNext = () => t('Next')
  const txSearch = () => t('Search')
  const txView = () => t('View')
  const txAddToRouteVarLength = (selectedRows: $TSFixMe) =>
    t('Add to route ({length})', {
      length: selectedRows?.length,
    })
  const txRemoveFromRoute = () => t('Remove from route')
  const txDisplayColumns = () => t('Display Columns')
  const txInert = () => t('Insert')
  const txWidth = () => t('Width')
  const txHeight = () => t('Height')

  const txClear = () => t('Clear')
  const txUndo = () => t('Undo')
  const txRedo = () => t('Redo')
  const txFontSize = () => t('Font size')
  const txColor = () => t('Color')
  const txTextColor = () => t('Text')
  const txTempColors = () => t('Temp Colors')
  const txBackgroundColor = () => t('Background')
  const txBold = () => t('Bold')
  const txLineHeight = () => t('Line Height')
  const txLetterSpacing = () => t('Letter Spacing')
  const txTextIndent = () => t('Text Indent')
  const txIncreaseIndent = () => t('Increase Indent')
  const txDecreaseIndent = () => t('Decrease Indent')
  const txItalic = () => t('Italic')
  const txUnderline = () => t('Underline')
  const txStrikeThrough = () => t('Strike Through')
  const txFontFamily = () => t('Font Family')
  const txTextAlign = () => t('Text Alignment')
  const txAlignLeft = () => t('Left Align')
  const txAlignCenter = () => t('Center Align')
  const txAlignRight = () => t('Right Align')
  const txAlignJustifytxAlignJustify = () => t('Justify Alignment')
  const txFloatLeft = () => t('Left Float')
  const txFloatRight = () => t('Right Float')
  const txSuperScript = () => t('Super Script')
  const txSubScript = () => t('Sub Script')
  const txRemoveStyles = () => t('Remove Styles')
  const txHeadings = () => t('Headings')
  const txHeader = () => t('Header')
  const txNormal = () => t('Normal')
  const txOrderedList = () => t('Ordered List')
  const txUnorderedList = () => t('Unordered List')
  const txBlockQuote = () => t('Quote')
  const txCode = () => t('Code')
  const txLink = () => t('Link')
  const txUnlink = () => t('Unlink')
  const txHr = () => t('Horizontal Line')
  const txMedia = () => t('Media')
  const txMediaLibirary = () => t('Media Library')
  const txEmoji = () => t('Emoji')
  const txFullscreen = () => t('Fullscreen')
  const txExitFullscreen = () => t('Exit Fullscreen')

  const txTextInputPlaceHolder = () => t('Input link ')
  const txLinkInputPlaceHolder = () => t('Input link URL')
  const txInputWithEnterPlaceHolder = () => t('Input link URL and press Enter')
  const txOpenInNewWindow = () => t('Open in new window')
  const txRemoveLink = () => t('Remove Link')

  const txAudioTitle = () => t('Play Audio')

  const txVideoTitle = () => t('Play Video')
  const txEmbedTitle = () => t('Embed Media')

  const txImage = () => t('Image')
  const txVideo = () => t('Video')
  const txAudio = () => t('Audio')
  const txEmbed = () => t('Embed')

  return {
    txCancel,
    txImage,
    txVideo,
    txAudio,
    txEmbed,
    txVideoTitle,
    txEmbedTitle,
    txAudioTitle,
    txTextInputPlaceHolder,
    txLinkInputPlaceHolder,
    txOpenInNewWindow,
    txRemoveLink,
    txInputWithEnterPlaceHolder,
    txHr,
    txExitFullscreen,
    txFullscreen,
    txEmoji,
    txMediaLibirary,
    txMedia,
    txUnlink,
    txLink,
    txCode,
    txUnorderedList,
    txBlockQuote,
    txHeader,
    txOrderedList,
    txNormal,
    txHeadings,
    txRemoveStyles,
    txSubScript,
    txSuperScript,
    txFloatRight,
    txFloatLeft,
    txAlignJustifytxAlignJustify,
    txAlignRight,
    txAlignCenter,
    txAlignLeft,
    txTextAlign,
    txFontFamily,
    txStrikeThrough,
    txUnderline,
    txDecreaseIndent,
    txItalic,
    txClear,
    txBackgroundColor,
    txBold,
    txIncreaseIndent,
    txTextIndent,
    txLetterSpacing,
    txLineHeight,
    txFontSize,
    txTempColors,
    txTextColor,
    txColor,
    txRedo,
    txUndo,
    txInert,
    txWidth,
    txHeight,
    txEmail,
    txDisplayColumns,
    txAddToRouteVarLength,
    txRemoveFromRoute,
    txNext,
    txView,
    txSearch,
    txResetPassword,
    txCountry,
    txWorkEmail,
    txSend,
    txUploadText,
    txFullName,
    txPhoneNumber,
    txPassword,
    txConfirmPassword,
    txShouldCreate,
    txShouldUpdate,
    txShouldUpdateGeoLocation,
    txUploadHint,
    txRemove,
    txLoading,
    txConfirm,
    txLongitude,
    txLatitude,
    txEdit,
    txRequiredField,
    txSubmit,
    txComp$TSFixMeName,
    txComments,
    txShowContainers,
    txShowDepots,
    txFilter,
    txSave,
    txEg123,
    txEg2$,
  }
}
