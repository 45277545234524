import { ReactNode } from 'react'
import { Tree, TreeDataNode, PopoverProps } from 'antd'

import styled from 'styled-components'

export const StyledTree = styled(Tree)`
  .ant-tree-switcher {
    display: none;
  }
`

export type UseTreeFilterAndOrderBaseArgs<T extends string> = {
  init: {
    checked: T[]
    order: T[]
  }
  columns: Record<T, TreeDataNode>
  popover?: Omit<PopoverProps, 'title' | 'content'>
  hideColumns?: T[]
}
export type UseTreeFilterAndOrderReturn<T> = {
  state: T[]
  jsx: {
    toggler: ReactNode
  }
}

export function arraymove<T>(arr: T[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}
