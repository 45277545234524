import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TDriverWorkStatus = `working` | `not-working` | `unknown`

export const driverWorkStatusLabelMap: Record<TDriverWorkStatus, ReactNode> = {
  working: <T _str="Working" />,
  'not-working': <T _str="Not working" />,
  unknown: <T _str="Unknown" />,
}

export const driverWorkStatusColorMap: Record<TDriverWorkStatus, string> = {
  working: 'blue',
  'not-working': 'red',
  unknown: 'default',
}
