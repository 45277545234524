import React, { FC, CSSProperties } from 'react'
import { Avatar, AvatarProps, Skeleton } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Typography, TTypographyProps } from '../Typography'

export type TAvatarWithNameProps = {
  avatarProps?: AvatarProps
  typographyProps?: TTypographyProps
  loading?: boolean
  wrapperProps?: CSSProperties
}

export const AvatarWithName: FC<TAvatarWithNameProps> = ({
  children,
  avatarProps,
  typographyProps,
  loading,
  wrapperProps,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        columnGap: 8,
        alignItems: 'center',
        ...wrapperProps,
      }}
    >
      <div className="user-avatar-div">
        {loading ? (
          <Skeleton.Avatar size="small" />
        ) : (
          <Avatar
            icon={
              !(
                avatarProps?.src ||
                avatarProps?.icon ||
                avatarProps?.children
              ) && <UserOutlined />
            }
            {...avatarProps}
          />
        )}
      </div>
      <div style={{ textAlign: 'start', flexGrow: 1 }}>
        <Typography
          loadingSize="full"
          loading={loading}
          ellipsis
          {...typographyProps}
        >
          {children}
        </Typography>
      </div>
    </div>
  )
}
