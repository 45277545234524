import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createLocationCreateRouteMap } from './create'
import { createLocationRouteMap } from './detailed-view'

export const createRoutesMapLocationMangementRouter = (prefix: string) => ({
  create: pathJoin(prefix)('create').chainMapAuto(
    createLocationCreateRouteMap,
    {
      title: <T _str="New Location" />,
      breadCrumbTitle: <T _str="New" />,
    }
  ),
  ':locationId': (locationId: string) =>
    pathJoin(prefix)(locationId).chainMapAuto(createLocationRouteMap, {
      title: <T _str="Location Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
})
