import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Select, SelectProps, Space, Avatar, AvatarProps } from 'antd'
import { GroupProps } from 'antd/es/avatar'
import { SelectValue } from 'antd/es/select'

type TSize = 'default' | 'small'

export type TSelectAvatarMultipleProps = Omit<
  SelectProps,
  'mode' | 'maxTagCount' | 'size'
> & {
  /** default to 3.  */
  maxAvatarCount?: number
  /** title of the selection.  */
  renderTitle: (value: SelectValue[] | undefined) => ReactNode
  getAvatarProps?: (value: SelectValue) => AvatarProps
  avatarProps?: AvatarProps
  avatarGroupProps?: GroupProps
  /** Size of the component. */
  size?: TSize
}

type TSizingValue = {
  selectSize: SelectProps['size']
  avatarGroupSize: GroupProps['size']
}

const sizingProps: Record<TSize, TSizingValue> = {
  small: {
    selectSize: 'middle',
    avatarGroupSize: 'small',
  },
  default: {
    selectSize: 'large',
    avatarGroupSize: 'default',
  },
}

const StyledSelect: typeof Select = styled(Select)`
  &&& {
    .ant-select-selection-overflow-item-rest {
      display: none;
    }
    .ant-avatar-group {
      display: block;
    }
  }
`

/**
 * SelectAvatarMultiple
 * - Ant-design select with only multiple mode support for multiple avatar selection.
 */
export const SelectAvatarMultiple = ({
  value,
  maxAvatarCount = 3,
  renderTitle,
  getAvatarProps,
  avatarProps,
  avatarGroupProps,
  size = 'default',
  ...rest
}: TSelectAvatarMultipleProps) => (
  <StyledSelect
    {...rest}
    value={value}
    mode="multiple"
    maxTagCount={1}
    size={sizingProps[size].selectSize}
    tagRender={() => (
      <Space align="center">
        <Avatar.Group
          {...avatarGroupProps}
          maxCount={maxAvatarCount}
          size={sizingProps[size].avatarGroupSize}
        >
          {React.Children.toArray(
            value?.map((v: SelectValue) => (
              <Avatar {...avatarProps} {...getAvatarProps?.(v)} />
            ))
          )}
        </Avatar.Group>
        {renderTitle(value)}
      </Space>
    )}
  />
)
