import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'

export const createContactRoutesMap = (prefix: string) => ({
  create: pathJoin(prefix)('create').chainWithTitle({
    title: <T _str="Create customer" />,
    breadCrumbTitle: <T _str="Create" />,
  }),
  'edit/:id': (id = '') =>
    pathJoin(prefix)('edit')(id).chainWithTitle({
      title: <T _str="Edit customer" />,
      breadCrumbTitle: <T _str="Edit" />,
    }),
  ':id': (id = '') =>
    pathJoin(prefix)(id).chainMapAuto(
      (prefix2) => ({
        general: pathJoin(prefix2)('general').chain,
        tickets: pathJoin(prefix2)('tickets').chain,
        invoices: pathJoin(prefix2)('invoices').chain,
        agreements: pathJoin(prefix2)('agreements').chain,
      }),
      {
        title: <T _str="Customer details" />,
        breadCrumbTitle: <T _str="Details" />,
      }
    ),
})
