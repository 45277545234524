import { isEqual } from 'lodash'
import { useRef } from 'react'

export const pipeFnAndObject = <T extends string>(
  val: T,
  ...args: Array<Record<string, string> | ((val: string) => string)>
): string =>
  args.reduce((acc, arg) => {
    if (typeof arg === 'function') return arg(acc) || acc
    return arg[acc] || acc
  }, val as $TSFixMe)

export const convertObjToFun =
  <T extends string, V extends unknown>(
    object: Record<T, V>
  ): ((val: T) => V) =>
  (val) =>
    object[val]

export const toCssUnit = (val: string | number, unit = 'px'): string => {
  if (typeof val === 'number') return `${val}${unit}`
  return val
}

// we will remove this

export function useDeepEqualMemo<T>(value: T) {
  const ref = useRef<T | undefined>(undefined)

  if (!isEqual(ref.current, value)) {
    ref.current = value
  }

  return ref.current
}

export const createOnPopupScroll =
  (fn?: () => void, offset = 2): React.UIEventHandler<HTMLDivElement> =>
  (e) => {
    e.persist()
    const { target } = e
    if (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollTop' does not exist on type 'Event... Remove this comment to see the full error message
      target.scrollTop + target.offsetHeight + offset >=
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollTop' does not exist on type 'Event... Remove this comment to see the full error message
      target.scrollHeight
    ) {
      fn?.()
    }
  }
