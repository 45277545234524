import React, {
  ReactNode,
  createContext,
  useContext,
  FC,
  useCallback,
  useRef,
} from 'react'
import { debounce } from 'lodash'
import {
  Space,
  Input,
  Button,
  Empty,
  Radio,
  Row,
  Col,
  InputRef,
  Checkbox,
  Tag,
  Divider,
} from 'antd'
import { CheckOutlined, SearchOutlined } from '@ant-design/icons'
import styled, { CSSProperties } from 'styled-components'
import { T } from '@transifex/react'
import { createPrefixer } from 'src/utils/createPrefixer'
import { createOnPopupScroll } from 'src/utils/others'
import { array } from 'src/utils/array'

import { StyledSkeletonInput } from '../Skeleton'
import { CssScrollerDiv } from '../CssScroller'
import { Typography } from '../Typography'
import { TX_NO_DATA, useTxStrings } from '../../transifex'
// eslint-disable-next-line import/no-cycle
import { Table } from './Table'

const p = createPrefixer('wh-UI-SelectableListWithSearch-')

const classes = {
  search: p`search`,
  optionsWrapper: p`optionsWrapper`,
  groupOptionsWrapper: p`groupOptionsWrapper`,
  groupOptionTitle: p`groupOptionTitle`,
  loaderWrapper: p`loaderWrapper`,
}

export type TOpt = {
  value: string
  label: ReactNode
  disabled?: boolean
  /** render on the right side of the option. */
  extra?: ReactNode
  style?: CSSProperties
}

const LIST_PADDING = 8

const StyledOptionSpace = styled.div<{
  $selected: boolean
  $background: CSSProperties['background']
  $disabled?: boolean
  $noHover?: boolean
}>`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;

  ${({ $background }) => `
    background: ${$background};
  `}

  &:hover {
    background: whitesmoke !important;
  }

  ${({ $disabled, $background }) =>
    $disabled
      ? `
      &:hover {
        background: ${$background} !important;
      }
  `
      : ``}

  ${({ $selected, $background }) =>
    $selected
      ? `
      background: ${$background} !important;
      &:hover {
        background: ${$background} !important;
      }
  `
      : ``}

  ${({ $noHover }) =>
    $noHover
      ? `
        &:hover {
          background: transparent !important;
        }
  `
      : ``}
`

type TVariant = 'default' | 'radio' | 'no-selection' | 'checkbox'

type TMode = 'multiple' | 'simple'

type TVal = string[]

type TStore = {
  value: TVal
  /** Allow selecting multiple options or one */
  mode?: TMode
  variant?: TVariant
  disabled?: boolean
  onChange?: (val: TVal) => void
  /** default value is true if mode is multiple and false for simple mode.
   * Toggle option state.
   */
  toggle?: boolean
  onlyLabelWhenCheckbox?: boolean
}

export type TSelectableListWithSearchProps = TStore & {
  showSearch?: boolean
  onSearch?: (val: string) => void
  /** Default search value */
  searchValue?: string
  loading?: boolean
  loadingMore?: boolean
  disabled?: boolean
  loaderNode?: ReactNode
  notFound?: boolean
  onMore?: () => void
  style?: CSSProperties
  /** Component to Render at top */
  addonTop?: ReactNode
  focusSearchOnRender?: boolean
  allSelected?: boolean
  someSelected?: boolean
  onCheckAll?: () => void
  onlyLabelWhenCheckbox?: boolean
}

export const variantConfig: Record<
  TVariant,
  {
    optionStyles: Omit<CSSProperties, 'paddingLeft' | 'padding'>
    rowGap: CSSProperties['rowGap']
    backgroundColor: CSSProperties['backgroundColor']
    optionBackground: CSSProperties['backgroundColor']
    radioEnabled: boolean
    checkboxEnabled?: boolean
    hideCheck?: boolean
    noHover?: boolean
    multipleEnabled: boolean
    optionDisabledColor: CSSProperties['color']
    paddingHorizontal: CSSProperties['padding']
  }
> = {
  radio: {
    optionStyles: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 16,
    },
    rowGap: 0,
    backgroundColor: 'whitesmoke',
    optionBackground: '#E6EFF8',
    radioEnabled: true,
    multipleEnabled: false,
    optionDisabledColor: '#f1f1f1',
    paddingHorizontal: LIST_PADDING,
  },
  default: {
    optionStyles: {
      paddingTop: LIST_PADDING,
      paddingBottom: LIST_PADDING,
      paddingRight: LIST_PADDING,
    },
    rowGap: 4,
    backgroundColor: 'white',
    optionBackground: '#E6F7FF',
    radioEnabled: false,
    multipleEnabled: true,
    optionDisabledColor: 'rgba(0, 0, 0, 0.03)',
    paddingHorizontal: 0,
  },
  'no-selection': {
    optionStyles: {
      paddingTop: LIST_PADDING,
      paddingBottom: LIST_PADDING,
      paddingRight: LIST_PADDING,
      cursor: 'default',
    },
    rowGap: 4,
    noHover: true,
    backgroundColor: 'white',
    optionBackground: '#E6F7FF',
    radioEnabled: false,
    multipleEnabled: true,
    optionDisabledColor: 'rgba(0, 0, 0, 0.03)',
    paddingHorizontal: 0,
  },
  checkbox: {
    optionStyles: {
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: LIST_PADDING,
      cursor: 'pointer',
    },
    rowGap: 8,
    noHover: true,
    hideCheck: true,
    backgroundColor: 'white',
    optionBackground: 'white',
    radioEnabled: false,
    checkboxEnabled: true,
    multipleEnabled: true,
    optionDisabledColor: 'white',
    paddingHorizontal: 0,
  },
}

const Context = createContext<TStore>({
  variant: 'default',
  mode: 'simple',
  value: [],
})

export const useOptContext = () => useContext(Context)

const OptionGroup: FC<{ title: ReactNode }> = ({ title, children }) => (
  <Space
    className={classes.groupOptionsWrapper}
    direction="vertical"
    style={{
      width: '100%',
      justifyContent: 'space-between',
      padding: `8px 0px`,
      cursor: 'pointer',
    }}
  >
    <Typography
      className={classes.groupOptionTitle}
      type="secondary"
      style={{ paddingLeft: 16 }}
    >
      {title}
    </Typography>
    {children}
  </Space>
)

const Option = ({
  label,
  value,
  inGroup,
  disabled,
  extra,
  style,
}: TOpt & { inGroup?: boolean }) => {
  const {
    value: currentValue,
    mode,
    onChange,
    variant,
    toggle,
    disabled: listDisabled,
    onlyLabelWhenCheckbox,
  } = useOptContext()
  const selected = currentValue?.includes(value)
  const variantProperties = variantConfig[variant ?? 'default']

  const optionDisabled = disabled || listDisabled

  return (
    <StyledOptionSpace
      $selected={selected}
      $disabled={optionDisabled}
      $background={
        array(
          variant === 'no-selection' && 'transparent',
          optionDisabled && variantProperties.optionDisabledColor,
          selected && variantProperties.optionBackground,
          'transparent'
        )[0]
      }
      $noHover={variantProperties.noHover}
      style={{
        width: '100%',
        justifyContent: 'space-between',
        cursor: optionDisabled ? 'not-allowed' : 'pointer',
        // eslint-disable-next-line no-nested-ternary
        paddingLeft: inGroup ? 24 : variant === 'checkbox' ? 0 : 16,
        ...variantProperties.optionStyles,
        ...style,
      }}
      onClick={() => {
        if (optionDisabled || variant === 'no-selection') return
        if (selected) {
          if (toggle) onChange?.(currentValue?.filter((val) => val !== value))
        } else {
          if (mode === 'simple' || !variantProperties.multipleEnabled) {
            onChange?.([value])
          }
          if (mode === 'multiple' && variantProperties.multipleEnabled) {
            onChange?.([...currentValue, value])
          }
        }
      }}
    >
      <div style={{ minWidth: 0, flexGrow: 1, overflow: 'hidden' }}>
        {variantProperties.radioEnabled && <Radio checked={selected} />}
        {variantProperties.checkboxEnabled && (
          <Checkbox
            style={{ marginRight: 8 }}
            disabled={optionDisabled}
            checked={selected}
          />
        )}
        {variant === 'checkbox' && !onlyLabelWhenCheckbox ? (
          <Tag>{label}</Tag>
        ) : (
          label
        )}
      </div>
      <Space size={4} style={{ flexGrow: 0 }}>
        {mode === 'multiple' &&
          selected &&
          variantProperties.multipleEnabled &&
          !variantProperties.hideCheck && (
            <Button type="link" ghost icon={<CheckOutlined />} />
          )}
        {extra}
      </Space>
    </StyledOptionSpace>
  )
}

const defaultToggleState: Record<TMode, boolean> = {
  simple: false,
  multiple: true,
}

const SelectableListWithSearch: FC<TSelectableListWithSearchProps> & {
  Table: typeof Table
  Option: typeof Option
  OptionGroup: typeof OptionGroup
  classes: typeof classes
} & {
  children?: ReactNode[]
} = ({
  variant = 'default',
  disabled = false,
  value,
  loading = false,
  loadingMore = false,
  showSearch = false,
  onSearch,
  searchValue,
  mode = 'simple',
  children,
  onChange,
  notFound,
  onMore,
  style,
  toggle: overwriteToggle,
  allSelected,
  someSelected,
  onCheckAll,
  onlyLabelWhenCheckbox,
  loaderNode = (
    <StyledSkeletonInput size="small" className="ant-skeleton-width-full" />
  ),
  addonTop,
}) => {
  const { txSearch } = useTxStrings()
  const variantProperties = variantConfig[variant ?? 'default']
  const inputRef = useRef<InputRef>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchHandler = useCallback(
    debounce((v) => {
      onSearch?.(v)
    }, 500),
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = useCallback(createOnPopupScroll(onMore), [onMore])

  return (
    <Context.Provider
      value={{
        mode,
        value,
        onChange,
        variant,
        disabled,
        toggle:
          overwriteToggle !== undefined
            ? overwriteToggle
            : defaultToggleState[mode],
        onlyLabelWhenCheckbox,
      }}
    >
      <div
        className="wh-vertical-scroller-container"
        style={{
          rowGap: showSearch ? LIST_PADDING : 0,
          backgroundColor: variantProperties.backgroundColor,
          paddingTop: LIST_PADDING, // 0 because Options have margin Top
          paddingBottom: LIST_PADDING,
          ...style,
        }}
      >
        <div
          style={{
            // padding: `0px ${variantProperties.paddingHorizontal}px`,
            paddingLeft: variantProperties.paddingHorizontal,
            paddingRight: variantProperties.paddingHorizontal,
          }}
        >
          <Row gutter={[0, 16]}>
            {addonTop && <Col xs={24}>{addonTop}</Col>}
            {showSearch && (
              <Col xs={24}>
                <Input
                  className={classes.search}
                  {...(searchValue ? { value: searchValue } : {})}
                  onChange={(e) => {
                    onSearchHandler(e.target.value)
                  }}
                  ref={inputRef}
                  disabled={disabled}
                  prefix={<SearchOutlined />}
                  placeholder={txSearch()}
                />
              </Col>
            )}
            {variant === 'checkbox' && mode === 'multiple' && (
              <Col xs={24}>
                <Checkbox
                  disabled={disabled}
                  checked={allSelected}
                  indeterminate={someSelected}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onCheckAll?.()
                    } else {
                      onChange?.([])
                    }
                  }}
                >
                  <Typography weight="semi-bold" variant="body-14">
                    <T _str="Select all" />
                  </Typography>
                </Checkbox>
                <Divider style={{ marginTop: 16, marginBottom: 8 }} />
              </Col>
            )}
          </Row>
        </div>
        <div className="wh-vertical-scroller-container-scroll">
          <CssScrollerDiv
            style={{
              height: `100%`,
              display: 'flex',
              flexDirection: 'column',
            }}
            defaultYScroller
            onScroll={onScroll}
          >
            {notFound && <Empty description={TX_NO_DATA} />}
            {!loading && (
              <div
                className={classes.optionsWrapper}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: variantProperties.rowGap,
                }}
              >
                {children}
              </div>
            )}
            {(loading || loadingMore) && (
              <Space
                direction="vertical"
                className={classes.loaderWrapper}
                style={{
                  paddingTop: loading ? 0 : LIST_PADDING,
                  paddingLeft: variantProperties.paddingHorizontal,
                  paddingRight: variantProperties.paddingHorizontal,
                  width: '100%',
                }}
              >
                {Array.from({ length: loadingMore ? 2 : 10 }).map(
                  () => loaderNode
                )}
              </Space>
            )}
          </CssScrollerDiv>
        </div>
      </div>
    </Context.Provider>
  )
}

SelectableListWithSearch.Table = Table
SelectableListWithSearch.Option = Option
SelectableListWithSearch.OptionGroup = OptionGroup
SelectableListWithSearch.classes = classes

export { SelectableListWithSearch }
