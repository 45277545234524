import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { getOptsFromValueMap } from '../../new-components/select/utils'

export type TCrmPropertyGroupGroupTypeChoices = `A_1` | `A_2`

export const crmPropertyGroupGroupTypeChoicesLabelMap: Record<
  TCrmPropertyGroupGroupTypeChoices,
  ReactNode
> = {
  A_1: <T _str="Company" />,
  A_2: <T _str="Individuals" />,
}

export const crmPropertyGroupGroupTypeChoicesLabelMap2: Record<
  TCrmPropertyGroupGroupTypeChoices,
  ReactNode
> = {
  A_1: <T _str="Single" />,
  A_2: <T _str="Split" />,
}

export const crmPropertyGroupGroupTypeChoicesOptions = getOptsFromValueMap(
  crmPropertyGroupGroupTypeChoicesLabelMap
)

export const crmPropertyGroupGroupTypeChoicesOptions2 = getOptsFromValueMap(
  crmPropertyGroupGroupTypeChoicesLabelMap2
)

export const crmPropertyGroupGroupTypeChoicesMutationValueMapFn = (
  val: TCrmPropertyGroupGroupTypeChoices
): string => val.split('_')[1]
