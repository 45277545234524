import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'
import { useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDelayQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
  delayMilliseconds = 0
) {
  const [isLoading, setIsLoading] = useState(true)
  const [queryData, setQueryData] = useState<TData>()

  const { loading, data, ...otherData } = useQuery(query, {
    ...options,
  })

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false)
    }, delayMilliseconds)

    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setQueryData(data)
    }
  }, [isLoading, data])

  return { loading: loading || isLoading, data: queryData, ...otherData }
}

export default useDelayQuery
