import { pathJoin } from '../../../../../utils'
import { createPlanRouteMap } from './subscription'

export const createSubscriptionRoutesMap = (prefix: string) => ({
  billingInformation: pathJoin(prefix)('billingInformation').chain,
  shippingInformation: pathJoin(prefix)('billingInformation').chain,
  paymentMethods: pathJoin(prefix)('paymentMethods').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  subscription:
    pathJoin(prefix)('subscription').chainMapAuto(createPlanRouteMap),
})
