import { useEffect, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { TableProps } from 'src/antd'
import { DEFAULT_PAGE_SIZE } from './pagination.config'
import { Init } from './useTableState'

const sortDirectionsMap: Record<string, string> = {
  ascend: 'asc',
  descend: 'desc',
}

type PersistentState = {
  pageSize: number
  orderBy: string
  order: string
}

const defaultInit = (init?: Init): PersistentState => ({
  order: init?.order || '',
  orderBy: init?.orderBy || '',
  pageSize: init?.pageSize || 10,
})

export const useTablePaginationAndSorterAPIHelper = <RecordType,>(
  key: string,
  init?: Init,
  addiotionalArgs?: { resetPageDep?: $TSFixMe }
) => {
  const [page, setPage] = useState(init?.page || 1)
  const [{ order, orderBy, pageSize }, setState] =
    useLocalStorageState<PersistentState>(`PSort-${key}`, {
      defaultValue: defaultInit(init),
    })

  const onTableChangeHandler: TableProps<RecordType>['onChange'] = (
    pagination,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    _,
    sorter
  ) => {
    if (!Array.isArray(sorter)) {
      setState((p) => ({
        ...p,
        orderBy: `${sorter.field || ''}`,
        order: sorter.order || ``,
      }))
      if (!sorter.order) {
        // the reset case.

        setState((p) => ({
          ...p,
          orderBy: ``,
        }))
      }
    }
    setState((p) => ({
      ...p,
      pageSize: pagination.pageSize || DEFAULT_PAGE_SIZE,
    }))
    setPage(pagination.current || 1)
  }

  useEffect(() => {
    setPage(init?.page || 1)
  }, [addiotionalArgs?.resetPageDep, init?.page, order, orderBy])

  return {
    paginationVars: {
      first: pageSize,
      offset: pageSize * (page - 1),
      order: sortDirectionsMap[order] || undefined,
      orderBy,
    },
    onTableChangeHandler,
    state: {
      order,
      orderBy,
      page,
      pageSize,
    },
  }
}
