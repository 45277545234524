import React, { useCallback, useEffect } from 'react'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { message } from 'src/antd'
import { useRefEnhanced } from '../../hooks'
import { getJWTToken } from './apollo'
import { cookies, COOKIES_CONST } from '../../Cookies'

const { host, protocol } = window.location
const secure = protocol.includes('s') ? 'wss' : 'ws'

const apiUrlCookie = cookies.get(COOKIES_CONST.API_URL)

export const wsUri = `${
  process.env.REACT_APP_API_SSL === 'true' ? 'wss' : secure
}://${apiUrlCookie || process.env.REACT_APP_API_URL || host}/ws`

const wsClient = new SubscriptionClient(`${wsUri}/graphql/`, {
  reconnect: true,
  connectionParams: () => {
    const jwtToken = getJWTToken()
    return jwtToken
      ? {
          auth_token: jwtToken,
        }
      : {}
  },
  timeout: 60 * 1000, //  1min
})

const DISCONNECTED_MESSAGE_KEY = 'DISCONNECTED_MESSAGE_KEY'

export const WsDisconnectNotification = () => {
  const isLoggedInListener = useSelector(
    (store: $TSFixMe) => store?.settings?.user?.id
  )

  const [getIsLoggedIn, setIsLoggedIn] = useRefEnhanced(isLoggedInListener)

  const removeNotification = useCallback(() => {
    message.destroy(DISCONNECTED_MESSAGE_KEY)
  }, [])

  const showNotification = useCallback(() => {
    message.error({
      key: DISCONNECTED_MESSAGE_KEY,
      content: <T _str="Disconnected" />,
      duration: 0,
    })
  }, [])

  useEffect(() => {
    setIsLoggedIn(isLoggedInListener)
    if (!isLoggedInListener) {
      removeNotification()
    }
  }, [isLoggedInListener, removeNotification, setIsLoggedIn])

  useEffect(() => {
    wsClient.onReconnected(() => {
      // need to close the message and set bool to false.
      removeNotification()
    })
    wsClient.onDisconnected(() => {
      if (getIsLoggedIn()) {
        showNotification()
      }
    })
  }, [getIsLoggedIn, removeNotification, showNotification])

  return <></>
}

export const wsLink = new WebSocketLink(wsClient)
