import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { getOptsFromValueMap } from '../../new-components/select/utils'

export type TCrmScheduledExportIntervalTypeChoices =
  | `HOUR`
  | `DAY`
  | 'WEEK'
  | 'MONTH'

export const crmScheduledExportIntervalTypeChoicesLabelMap: Record<
  TCrmScheduledExportIntervalTypeChoices,
  ReactNode
> = {
  DAY: <T _str="Day" />,
  HOUR: <T _str="Hour" />,
  MONTH: <T _str="Month" />,
  WEEK: <T _str="Week" />,
}
export const crmScheduledExportIntervalTypeChoicesLabelMap2: Record<
  TCrmScheduledExportIntervalTypeChoices,
  ReactNode
> = {
  DAY: <T _str="Daily" />,
  HOUR: <T _str="Hourly" />,
  MONTH: <T _str="Monthly" />,
  WEEK: <T _str="Weekly" />,
}

export const crmScheduledExportIntervalTypeChoicesOptions = getOptsFromValueMap(
  crmScheduledExportIntervalTypeChoicesLabelMap
)
