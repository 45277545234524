import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createRouteMapCreateRouteScheme } from './create'
import { createRouteMapEditRouteScheme } from './detailed-view'

export const createRouteSchemeRouteMap = (prefix: string) => ({
  create: pathJoin(prefix)('create').chainMapAuto(
    createRouteMapCreateRouteScheme,
    {
      title: <T _str="New Route Scheme" />,
      breadCrumbTitle: <T _str="New" />,
    }
  ),
  'edit/:id': (id: string) =>
    pathJoin(prefix)('edit')(id).chainMapAuto(createRouteMapEditRouteScheme, {
      title: <T _str="Route Scheme Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
})
