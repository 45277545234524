import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../utils'
import { createRoutesMapContainers } from './containers'
import { createRoutesMapContainerGroup } from './container-group'

export const createRoutesMapAssetManagement = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chainMapAuto(
    createRoutesMapContainers,
    {
      title: <T _str="All Containers" />,
      breadCrumbTitle: <T _str="All Containers" />,
    }
  ),
  'container-group': pathJoin(prefix)(`container-group`).chainMapAuto(
    createRoutesMapContainerGroup,
    {
      title: <T _str="All Container Groups" />,
      breadCrumbTitle: <T _str="All Container Groups" />,
    }
  ),
  'containers-map-by-feature': pathJoin(prefix)(
    'containers-map-by-feature'
  ).chainWithTitle({
    breadCrumbTitle: <T _str="Map by features" />,
    title: <T _str="Map by features" />,
  }),
})
