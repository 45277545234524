import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import createStore from 'zustand'
import create from 'zustand/vanilla'
import { produce } from 'immer'
import { uniqueId } from 'lodash'
import { useHistory } from 'react-router'
import Modal, { IModal } from 'src/storybook/components/Modal'

type Store = {
  jsxMap: Record<string, JSX.Element>
}

const store = create<Store>(() => ({
  jsxMap: {},
}))

type ModalProps = Omit<IModal, 'onClose'> & { onClose?: () => void }

export type ResolveReject = {
  resolve: (val: unknown) => void
  reject: (reason?: $TSFixMe) => void
}

type BaseModalProps = Omit<ModalProps, 'visible' | 'footer'>

type ChildModalProps = BaseModalProps & {
  children: (args: ResolveReject) => JSX.Element
  _internal: ResolveReject
  modalPropsWithActions?: (args: ResolveReject) => BaseModalProps
  /** @default false
   * set it to true to close the modal when the route changes
   */
  closeOnRouteChange?: boolean
}

const CANCEL = `CANCEL`

const ChildModal = ({
  children,
  _internal,
  modalPropsWithActions,
  closeOnRouteChange,
  ...rest
}: ChildModalProps) => {
  const history = useHistory()

  useEffect(() => {
    // Todo: use a reusable hook for this
    const unlisten = history.listen(() => {
      if (closeOnRouteChange) {
        _internal.reject(CANCEL)
      }
    })

    // cleanup the listener when the component unmounts.
    return () => {
      unlisten()
    }
  }, [_internal, closeOnRouteChange, history])

  return (
    <Modal
      {...rest}
      onClose={() => _internal.reject(CANCEL)}
      {...modalPropsWithActions?.(_internal)}
      visible
      footer={null}
    >
      {children(_internal)}
    </Modal>
  )
}

export type TAsyncModalArgs = Omit<ChildModalProps, '_internal'>

const asyncModal = (props: TAsyncModalArgs) => {
  const { setState } = store
  const myId = uniqueId()
  const div = document.createElement('div')
  document.body.appendChild(div)

  function destory() {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.jsxMap[myId] = <></>
      })
    )
    const unmountResult = ReactDOM.unmountComponentAtNode(div)
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div)
    }
  }

  return new Promise((res, rej) => {
    setState(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.jsxMap[myId] = ReactDOM.createPortal(
          <ChildModal
            {...props}
            _internal={{
              resolve: (val) => {
                destory()
                res(val)
              },
              reject: (err) => {
                destory()
                rej(err)
              },
            }}
          />,
          div
        )
      })
    )
  })
}

asyncModal.CANCEL = CANCEL

export { asyncModal }

const useStore = createStore(store)

export const AsyncModalPortal = () => {
  const jsxMap = useStore((state) => state.jsxMap)

  return <>{Object.values(jsxMap)}</>
}
