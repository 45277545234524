import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from '@ant-design/icons'
import { Col, Row } from 'antd'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import React, { ReactNode } from 'react'
import ControlBarButton from './ControlBarButton'
import {
  TX_CENTER_ALIGN,
  TX_LEFT_ALIGN,
  TX_RIGHT_ALIGN,
} from '../../../transifex'

type TAlignment = 'left' | 'center' | 'right'

const alignmetsData: Record<
  TAlignment,
  { icon: ReactNode; tooltip?: ReactNode }
> = {
  left: {
    icon: <AlignLeftOutlined />,
    tooltip: TX_LEFT_ALIGN,
  },
  center: {
    icon: <AlignCenterOutlined />,
    tooltip: TX_CENTER_ALIGN,
  },
  right: {
    icon: <AlignRightOutlined />,
    tooltip: TX_RIGHT_ALIGN,
  },
}

type AlignmentProps = {
  editorState: BraftEditor
  onAlign: (alignment: TAlignment) => void
}

const Alignment = ({ editorState, onAlign }: AlignmentProps) => {
  const currentAlignment = ContentUtils.getSelectionBlockData(
    editorState,
    'textAlign'
  )

  return (
    <Row gutter={8}>
      {Object.keys(alignmetsData).map((alignment) => {
        const alignmentData = alignmetsData[alignment as TAlignment]
        return (
          <Col>
            <ControlBarButton
              tooltip={alignmentData.tooltip}
              active={currentAlignment === alignment}
              onClick={() => onAlign(alignment as TAlignment)}
              icon={alignmentData.icon}
            />
          </Col>
        )
      })}
    </Row>
  )
}

export default Alignment
