import React, { useMemo } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import styled from 'styled-components'
import {
  TX_SIZE_12,
  TX_SIZE_16,
  TX_SIZE_24,
  TX_SIZE_32,
} from '../../../transifex'

const DEFAULT_SIZE = 16

const fontSizesItems = [
  {
    key: '32',
    label: TX_SIZE_32,
  },
  {
    key: '24',
    label: TX_SIZE_24,
  },
  {
    key: '16',
    label: TX_SIZE_16,
  },
  {
    key: '12',
    label: TX_SIZE_12,
  },
]

const fontSizes = fontSizesItems.map((item) => +item.key)

const DropDownView = styled.div`
  width: 180px;
  height: 32px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 5px 18px 5px 16px;
  background-color: white;
`

type FontSizeProps = {
  editorState: BraftEditor
  onFontSizeChange?: (fontSize: string) => void
}

const FontSize = ({ editorState, onFontSizeChange }: FontSizeProps) => {
  const currentFontSize = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const fontSize of fontSizes) {
      if (
        ContentUtils.selectionHasInlineStyle(
          editorState,
          `FONTSIZE-${fontSize}`
        )
      ) {
        return fontSize
      }
    }
    return DEFAULT_SIZE
  }, [editorState])

  /**
   * Using Drop down because Antd Select causes
   * The Editor to loose focus and setting font size
   * with no focus is buggy
   */

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu
          selectedKeys={[`${currentFontSize}`]}
          onClick={(opt) => onFontSizeChange?.(opt.key)}
          selectable
          items={fontSizesItems}
        />
      }
    >
      <DropDownView>
        {
          fontSizesItems.find((item) => item.key === `${currentFontSize}`)
            ?.label
        }
        <DownOutlined />
      </DropDownView>
    </Dropdown>
  )
}

export default FontSize
