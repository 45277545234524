import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TJobTitle =
  | `Account manager`
  | `Administration`
  | `Driver`
  | `Route planner`
  | `Fleet manager`
  | `Service`

export const jobTitleLabelMap: Record<TJobTitle, ReactNode> = {
  'Account manager': <T _str="Account manager" />,
  Administration: <T _str="Administration" />,
  Driver: <T _str="Driver" />,
  'Route planner': <T _str="Route planner" />,
  'Fleet manager': <T _str="Fleet manager" />,
  Service: <T _str="Service" />,
}

export const jobTitleColorMap: Record<TJobTitle, string> = {
  'Account manager': 'gold',
  Administration: 'magenta',
  Driver: 'blue',
  'Route planner': 'volcano',
  'Fleet manager': 'geekblue',
  Service: 'cyan',
}
