import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Switch,
  Route,
  RouteProps,
  Redirect,
  RouteChildrenProps,
  useHistory,
  Link as RouteLink,
  LinkProps as RouteLinkProps,
} from 'react-router-dom'
import { History } from 'history'
import InfoPopover, { TInfoPopoverProps } from 'src/new-components/InfoPopover'
import { Role } from './utils'
import { routingMap } from './routing-map'
import { ErrorBoundary } from '../../setupSentry'

export type RolesFlag = `*`
export type Fallback = (roles: Role[]) => string

export type Roles = {
  or?: Role[]
  and?: Role[]
  flag?: RolesFlag
}

export type RouteConfig<T extends string> = {
  key: T
  roles: Roles
  fallback: Fallback
  conds?: CondSet[]
  path: string
} & Omit<RouteProps, `render` | `component` | `key` | `path`>

export type RouterFactoryProps<T extends string> = {
  config: RouteConfig<T>[]
  defaultRedirect?: string
  notFound?: React.FC
}

export type Routing = {
  roles: Role[]
  routingMap: typeof routingMap
  init: boolean
}

/* 
Todo: aka Future development.
1. I see RouteFactory has a factory where we will give our config and it will take care of the rest.
This includes Authentication, Authorization and all required Redirects.
For now, it only create basic routing, but I hope we will achieve the above as we continue working.
By __ @shaaandi at github. saif.awan.10441@gmail.com.
*/

type CondSet = [boolean, () => void]

export type RoleCheckerProps = {
  allowedRoles: Roles
  fallback: Fallback
  conds?: CondSet[]
  children: ((props: RouteChildrenProps) => React.ReactNode) | React.ReactNode
}

export type RoutingUtilCheckRoleT = (args: {
  allowedRoles: Roles
  givenRoles: Role[]
}) => boolean

export const routingUtilCheckRole: RoutingUtilCheckRoleT = ({
  allowedRoles,
  givenRoles,
}) => {
  const { and, flag, or } = allowedRoles

  if (flag === '*') return true

  if (
    (and?.length
      ? and?.every((role) => givenRoles.indexOf(role) !== -1)
      : true) &&
    (or?.length ? or?.some((role) => givenRoles.indexOf(role) !== -1) : true)
  ) {
    return true
  }

  return false
}

export type HookUseRoleCheckerT = () => [
  (allowedRoles: Roles) => boolean,
  Routing
]
export const useRoleChecker: HookUseRoleCheckerT = () => {
  const routing = useSelector(
    (state: $TSFixMe) => state.settings.routing
  ) as Routing
  const { roles: givenRoles } = routing

  return [
    (allowedRoles) => routingUtilCheckRole({ givenRoles, allowedRoles }),
    routing,
  ]
}

export const RoleChecker: React.FC<RoleCheckerProps> = ({
  allowedRoles,
  fallback,
  conds,
  children,
}) => {
  conds?.forEach((condSet) => condSet[0] && condSet[1]())
  const [checkRoleFunc, { roles }] = useRoleChecker()

  if (checkRoleFunc(allowedRoles)) return <>{children}</>

  return <Redirect to={fallback(roles)} />
}

const RouterFactory = <T extends string>({
  config,
  defaultRedirect = ``,
  notFound,
}: RouterFactoryProps<T>): JSX.Element => {
  return (
    <Switch>
      {config.map(({ children, roles, fallback, conds, ...rest }) => (
        <Route {...rest}>
          {(props) => (
            <RoleChecker allowedRoles={roles} fallback={fallback} conds={conds}>
              <ErrorBoundary>
                {typeof children === 'function' ? children?.(props) : children}
              </ErrorBoundary>
            </RoleChecker>
          )}
        </Route>
      ))}
      {notFound ? (
        <Route component={notFound} />
      ) : (
        <Redirect to={defaultRedirect} />
      )}
    </Switch>
  )
}

const useRouting = () => {
  const routing: Routing = useSelector(
    (state: $TSFixMe) => state.settings.routing
  )

  return routing
}

export const useRoutingHistory = (): [History<unknown>, Routing] => {
  const history = useHistory()
  const routing = useRouting()

  return [history, routing]
}

useRouting.useHistory = useHistory

export { useRouting }

export default RouterFactory

export type LinkProps = Omit<RouteLinkProps, 'to'> & {
  to: (routing: Routing) => RouteLinkProps['to']
  infoPopover?: TInfoPopoverProps
}

export const Link: FC<LinkProps> = ({ to, infoPopover, ...rest }) => {
  const routing = useRouting()

  return (
    <>
      {infoPopover ? (
        <InfoPopover {...infoPopover}>
          <RouteLink {...rest} to={to(routing)} />
        </InfoPopover>
      ) : (
        <RouteLink {...rest} to={to(routing)} />
      )}
    </>
  )
}
