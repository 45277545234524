import { createBrowserHistory } from 'history'
import { useHistoryStore } from './reducers/routing/store'

const history = createBrowserHistory()
// Todo: Remvoe prevPath code and see it's not being used anywhere.
let prevPath: string | null = null

const originalHistoryPush = history.push

history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname
  }
})

history.push = (...args) => {
  const { commandKeyDown } = useHistoryStore.getState()

  if (commandKeyDown) {
    const location =
      typeof args?.[0] === 'string' ? args?.[0] : args?.[0]?.pathname
    window.open(location, '_blank', 'noreferrer')
    return
  }
  originalHistoryPush(...args)
}

export default history
