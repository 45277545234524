import React, { ReactNode, useMemo } from 'react'
import { Select, SelectProps } from 'antd'
import styled from 'styled-components'

export type TOptColors = {
  primary: string
  secondary: string
}

export type TOpt = {
  label: ReactNode
  optLabel?: ReactNode
  value: string
  colors: TOptColors
}

export type TSelectFilledProps = {
  options?: TOpt[]
  /** Suffix Icon Of Select. */
  suffixIcon?: (colors?: TOptColors) => ReactNode
} & Omit<SelectProps, 'options' | 'suffixIcon'>

type StyledSelectProps = SelectProps & {
  $backgroundColor: string
}

const StyledSelect: React.FC<StyledSelectProps> = styled(
  Select
)<StyledSelectProps>`
  & .ant-select-selector {
    background-color: ${(props) => props.$backgroundColor} !important;
  }
`

export const SelectFilled: React.FC<TSelectFilledProps> = ({
  options,
  value,
  suffixIcon,
  ...props
}) => {
  const optionsMap = useMemo(
    () =>
      options?.reduce(
        (prevObj, currentValue) => ({
          ...prevObj,
          [currentValue.value]: {
            label: currentValue.label,
            optLabel: currentValue.optLabel,
            colors: currentValue.colors,
          },
        }),
        {} as Record<string, Omit<TOpt, 'value'>>
      ),
    [options]
  )

  return (
    <StyledSelect
      {...props}
      value={value}
      optionLabelProp="optLabel"
      $backgroundColor={
        optionsMap?.[value as string]?.colors.secondary ?? 'transparent'
      }
      options={options?.map((opt) => ({
        label: opt.label,
        value: opt.value,
        optLabel: opt.optLabel ?? opt.label,
      }))}
      suffixIcon={suffixIcon?.(optionsMap?.[value as string]?.colors)}
    />
  )
}
