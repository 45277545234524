import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdCardboardFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.9691 0.535499C12.0243 0.470568 11.9782 0.370667 11.8929 0.370667H5.0704C5.01517 0.370667 4.9704 0.415438 4.9704 0.470667V3.96801C4.9704 4.02324 5.01517 4.06801 5.0704 4.06801H8.91483C8.94413 4.06801 8.97196 4.05516 8.99096 4.03284L11.9691 0.535499Z" />
      <path d="M4.64119 4.06801C4.69642 4.06801 4.74119 4.02324 4.74119 3.96801V0.737613C4.74119 0.644613 4.62535 0.601973 4.56505 0.672779L1.81416 3.90317C1.75886 3.9681 1.80501 4.06801 1.89029 4.06801H4.64119Z" />
      <path d="M1.56398 7.74311V12.5293C1.56398 12.5846 1.60876 12.6293 1.66399 12.6293L8.70409 12.6288C8.75932 12.6288 8.80408 12.5841 8.80408 12.5288V4.89258L8.8041 4.87809L7.9079 7.74311H1.56398Z" />
      <path d="M8.34661 5.56485L8.71854 4.376L1.49306 4.3796L0.720828 6.8401L0.528687 7.45193L7.75691 7.44887L8.34661 5.56485Z" />
      <path d="M12.216 5.36427C12.216 5.27128 12.1002 5.22864 12.0399 5.29943L10.3016 7.33989C10.2537 7.39609 10.1636 7.38282 10.1339 7.31522L9.24684 5.29321C9.20334 5.19406 9.05526 5.22511 9.05526 5.33338V12.2468C9.05526 12.3398 9.17109 12.3825 9.23139 12.3117L12.1921 8.83543C12.2075 8.81734 12.216 8.79435 12.216 8.77059V5.36427Z" />
      <path d="M13.4494 3.16495L12.3657 0.6949C12.336 0.627291 12.2458 0.614032 12.198 0.670244L9.12693 4.27682C9.1021 4.30598 9.09611 4.34675 9.11149 4.38182L10.1915 6.84426C10.2212 6.91188 10.3114 6.92514 10.3592 6.86893L13.4361 3.25608C13.4576 3.23077 13.4628 3.19536 13.4494 3.16495Z" />
    </svg>
  )
})
