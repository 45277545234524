import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TUserStatus = `active` | `pending` | `invited`

export const userStatusLabelMap: Record<TUserStatus, ReactNode> = {
  active: <T _str="Active" />,
  pending: <T _str="Pending" />,
  invited: <T _str="Invited" />,
}

export const userStatusColorMap: Record<TUserStatus, string> = {
  active: 'green',
  pending: 'orange',
  invited: 'blue',
}

export type TUserStatusOnActive = 'active' | 'non-active'

export const userStatusOnActiveLabelMap: Record<
  TUserStatusOnActive,
  ReactNode
> = {
  active: <T _str="Active" />,
  'non-active': <T _str="Pending / Invited" />,
}
