import React from 'react'
import { T } from '@transifex/react'
import { Empty as AntdEmpty, EmptyProps, Space } from 'antd'
import {
  DownCircleFilled,
  LeftCircleFilled,
  RightCircleFilled,
  UpCircleFilled,
} from '@ant-design/icons'
import { Typography } from 'src/storybook/components'

import Img from '../images/empty/Exclamation.png'
import List from '../images/empty/List.png'

export const Empty = (props: Omit<EmptyProps, 'image' | 'imageStyle'>) => {
  return (
    <AntdEmpty
      description={
        <span>
          <T _str="No data found" />
        </span>
      }
      {...props}
      image={Img}
      imageStyle={{
        height: 50,
      }}
    />
  )
}

type EmptySelectItemProps = {
  direction?: 'top' | 'left' | 'right' | 'bottom'
  size?: 'default'
}

const sizeMap: Record<
  Exclude<EmptySelectItemProps['size'], undefined>,
  number
> = {
  default: 80,
}

export const EmptySelectItem = ({
  direction = 'left',
  size = 'default',
}: EmptySelectItemProps) => {
  return (
    <AntdEmpty
      image={List}
      imageStyle={{
        height: sizeMap[size],
      }}
      description={
        <Typography type="secondary">
          <Space>
            {direction === 'bottom' && (
              <>
                <DownCircleFilled /> <T _str="Select an item at the bottom" />
              </>
            )}
            {direction === 'left' && (
              <>
                <LeftCircleFilled /> <T _str="Select an item on the left" />
              </>
            )}
            {direction === 'right' && (
              <>
                <RightCircleFilled /> <T _str="Select an item on the right" />
              </>
            )}
            {direction === 'top' && (
              <>
                <UpCircleFilled /> <T _str="Select an item at the top" />
              </>
            )}
          </Space>
        </Typography>
      }
    />
  )
}
