import React from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type AbsenceTypeType = `holiday` | `sick` | `maternity`

export const absenceTypeLabelMap: Record<AbsenceTypeType, React.ReactNode> = {
  holiday: <T _str="Holiday" />,
  sick: <T _str="Sick" />,
  maternity: <T _str="Maternity" />,
}

export const ABSENCE_TYPE_OPTIONS =
  createOptionsFromLabelMap(absenceTypeLabelMap)
