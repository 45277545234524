import { useEffect } from 'react'
import { useRefEnhanced } from './useRefEnhanced'

export const useRefCopy = <T extends $TSFixMe>(state: T) => {
  const [getRef, setRef] = useRefEnhanced<T>(state)

  useEffect(() => {
    setRef(state)
  }, [setRef, state])

  return getRef
}
