import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../utils'
import { createPropertyGroupsRouteMap } from './property-groups'
import { createContainerRoutesMap } from './assets'
import { createPropertyRoutesMap } from './property'
import { createContactRoutesMap } from './contact'

export const createCustomerManagementRouteMap = (prefix: string) => ({
  'inquiry-management': pathJoin(prefix)('inquiry-management').chain,
  'property-groups': pathJoin(prefix)('property-groups').chainMapAuto(
    createPropertyGroupsRouteMap,
    {
      title: <T _str="All Property Groups" />,
      breadCrumbTitle: <T _str="All Property Groups" />,
    }
  ),
  properties: pathJoin(prefix)('properties').chainMapAuto(
    createPropertyRoutesMap,
    {
      title: <T _str="All Properties" />,
      breadCrumbTitle: <T _str="All Properties" />,
    }
  ),
  customers: pathJoin(prefix)('customers').chainMapAuto(
    createContactRoutesMap,
    {
      title: <T _str="Customers" />,
      breadCrumbTitle: <T _str="Customers" />,
    }
  ),
  assets: pathJoin(prefix)('assets').chainMapAuto(createContainerRoutesMap, {
    title: <T _str="Assets" />,
    breadCrumbTitle: <T _str="Assets" />,
  }),
})
