import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../utils'

import { createDashboardsRoutesMap } from './dashboards'

export const createAnalyticsRoutesMap = (prefix: string) => ({
  exports: pathJoin(prefix)('exports').chainWithTitle({
    title: <T _str="Analytics Exports" />,
    breadCrumbTitle: <T _str="Exports" />,
  }),
  dashboards: pathJoin(prefix)('dashboards').chainMapAuto(
    createDashboardsRoutesMap
  ),
})
