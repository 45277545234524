import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type RoutesPickUpOrderStatusChoices =
  | `CONNECTED_TO_ROUTE`
  | `COMPLETED`
  | `SKIPPED`

export const routesPickUpOrderStatusChoicesLabelMap: Record<
  RoutesPickUpOrderStatusChoices,
  ReactNode
> = {
  COMPLETED: <T _str="Completed" />,
  CONNECTED_TO_ROUTE: <T _str="Connected to route" />,
  SKIPPED: <T _str="Skipped" />,
}
