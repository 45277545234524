import { gql } from '@apollo/client'

/* Queries */
export const GET_ME = gql`
  query GetMe($projectList: [ID]) {
    me {
      id
      isStaff
      isAdmin
      isSuperuser
      isDemo
      isReseller
      isMaster
      isBeta
      username
      userId
      firstName
      lastName
      email
      phoneNumber
      mixpanelIdentity
      datetimeFormatLocale
      logo
      activeProjectsIds
      daysBeforeDemoExpires
      language
      ownProjectsIds
      ownProjects {
        edges {
          node {
            id
            name
          }
        }
      }
      jobtitle {
        id
        title
      }
      activeProjects(projectsIds: $projectList) {
        edges {
          node {
            id
            name
            userAccessLevel
            logo
            company {
              id
              name
              dashboardLocation {
                id
                latitude
                longitude
                placeId
                name
              }
            }
            settings {
              id
              timezone
              emptyingMethod
              pickupTime
              cluster
              currency
              measuringDistance
              drivingDistance
              weight
              volume
              temperature
              dashboardLocation {
                id
                latitude
                longitude
                placeId
                name
              }
            }
            containerTypes {
              edges {
                node {
                  id
                  name
                  length
                  width
                  height
                  diameter
                  isCylindrical
                  volume
                }
              }
            }
            measurementsettingsSet {
              edges {
                node {
                  id
                }
              }
            }
            wasteFractions {
              edges {
                node {
                  id
                  name
                  wasteCategory
                  wasteSubstance
                  weightFactor
                }
              }
            }
          }
        }
      }
      company {
        id
        logo
        name
        companySettings {
          id
          mapCountry
        }
        dashboardLocation {
          id
          latitude
          longitude
          placeId
          name
        }
      }
      userLayoutConfig {
        id
        driverOrder
        driverOrderBy
        driverPageSize
        driverIconsSize
        driverDisabledColumns
        locationsOrder
        locationsOrderBy
        locationsPageSize
        locationsIconsSize
        locationsDisabledColumns
        vehicleOrder
        vehicleOrderBy
        vehiclePageSize
        vehicleIconsSize
        vehicleDisabledColumns
        vehicleTypesOrder
        vehicleTypesOrderBy
        vehicleTypesPageSize
        vehicleTypesDisabledColumns
        routeOrder
        routeOrderBy
        routePageSize
        routeDisabledColumns
        inquiryOrder
        inquiryOrderBy
        inquiryPageSize
        inquiryDisabledColumns
        containersOrder
        containersOrderBy
        containersPageSize
        containersDisabledColumns
        collectionContainersOrder
        collectionContainersOrderBy
        collectionContainersPageSize
        collectionContainersDisabledColumns
        pickupOrdersOrder
        pickupOrdersOrderBy
        pickupOrdersPageSize
        pickupOrdersDisabledColumns
        notificationsOrder
        notificationsOrderBy
        notificationsPageSize
        notificationsDisabledColumns
        analyticsCalculationType
        analyticsPreviousPeriod
        analyticsCustomPeriodFrom
        analyticsCustomPeriodTo
        analyticsCustomPeriodComparisonFrom
        analyticsCustomPeriodComparisonTo
        analyticsHistoricalPeriod
      }
    }
  }
`

/* Mutations */
export const MUTATION_VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload {
        username
        exp
        origIat
      }
      user {
        username
        lastName
        firstName
      }
    }
  }
`
