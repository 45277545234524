import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../utils'
import { createOffersRoutesMap } from './offers'
import { createCustomerDetailRouteMap } from './customer-detailed-view'
import { createCustomerCreateRouteMap } from './create-customer'

export const createControlCenterRoutesMap = (prefix: string) => ({
  leads: pathJoin(prefix)('leads').chainWithTitle({
    title: <T _str="Leads" />,
    breadCrumbTitle: <T _str="Leads" />,
  }),
  customers: pathJoin(prefix)('customers').chainWithTitle({
    title: <T _str="Customers" />,
    breadCrumbTitle: <T _str="Customers" />,
  }),
  offers: pathJoin(prefix)('offers').chainMapAuto(createOffersRoutesMap, {
    title: <T _str="Offers" />,
    breadCrumbTitle: <T _str="Offers" />,
  }),
  'customers/:id': (id: string) =>
    pathJoin(prefix)('customers')(id).chainMapAuto(
      createCustomerDetailRouteMap,
      {
        title: <T _str="Customer Detail" />,
        breadCrumbTitle: <T _str="Customer Detail" />,
      }
    ),
  create: pathJoin(prefix)('create').chainMapAuto(
    createCustomerCreateRouteMap,
    {
      title: <T _str="Create Customer" />,
      breadCrumbTitle: <T _str="Create Customer" />,
    }
  ),
  'test-sockets': pathJoin(prefix)('test-sockets').chain,
  'error-test': pathJoin(prefix)('error-test').chain,
})
