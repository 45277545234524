import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createDetailRoutesMap } from './detailed-view'

export const createPropertyRoutesMap = (prefix: string) => ({
  '/:id': (id: string) =>
    pathJoin(prefix)(id).chainMapAuto(createDetailRoutesMap, {
      title: <T _str="Property Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
  search: pathJoin(prefix)('search').chainWithTitle({
    title: <T _str="Search Property" />,
    breadCrumbTitle: <T _str="Search" />,
  }),
  create: pathJoin(prefix)('create').chainWithTitle({
    title: <T _str="New Property" />,
    breadCrumbTitle: <T _str="New Property" />,
  }),
  'edit/:id': (id: string) =>
    pathJoin(prefix)('edit')(id).chainWithTitle({
      title: <T _str="Edit Property" />,
      breadCrumbTitle: <T _str="Edit" />,
    }),
})
