/* eslint-disable no-restricted-syntax */
import { ReactNode } from 'react'
import { memoize } from 'lodash'
import { impersonatorTokenKey } from 'src/actions'
import { routingMap } from './routing-map'
import { RouteConfig } from './child-router-factory'

export type Role =
  | `authenticated`
  | `non-authenticated`
  | `wastehero-staff`
  | `impersonator`

export type Middleware = (roles: Role[], opt?: string) => string
export type WithRoutingMap = (map: typeof routingMap) => Middleware

export type RoutingMap = typeof routingMap

export type RoutingState = {
  roles: Role[]
  routingMap: typeof routingMap
  init: boolean
}

type ANY = Record<string, unknown>

type ChainWithTitleArgs = {
  title: ReactNode
  breadCrumbTitle: ReactNode
}

export type CreateConfigFn<Keys extends string, Conds extends ANY = ANY> = (
  map: typeof routingMap,
  utils?: {
    conds: Conds
  }
) => RouteConfig<Keys>[]

export type CreateConfigFnExtend<
  T extends ANY,
  Keys extends string,
  Conds extends ANY = ANY
> = (props: T) => CreateConfigFn<Keys, Conds>

export const nonAuthenticatedRedirect: WithRoutingMap =
  (map) =>
  (rolesC, opt = ``) => {
    if (rolesC.indexOf('authenticated') === -1) return map.login._
    return opt
  }

export const authenticatedRedirect: WithRoutingMap =
  (map) =>
  (rolesC, opt = ``) => {
    if (rolesC.indexOf('non-authenticated') === -1) return map.app.dashboard._
    return opt
  }

export function setRoles(user: $TSFixMe): Role[] {
  let givenRoles: Role[] = []
  if (user && user.id) {
    givenRoles = ['authenticated']
    if (user.isStaff) {
      givenRoles = [...givenRoles, `wastehero-staff`]
    }
    // if there is root token available then this user is impersonating.
    if (localStorage.getItem(impersonatorTokenKey)) {
      givenRoles.push('impersonator')
    }
  } else givenRoles = ['non-authenticated']

  return givenRoles
}

export const slashJoin = (...args: string[]): string =>
  '/'.concat(args.join('/'))

export const pathJoin = (...args: string[]) => {
  const resolve = (...args1: string[]) => {
    return pathJoin(...args, ...args1)
  }
  resolve.value = '/'.concat(args.filter((v) => !!v).join('/'))
  resolve.chain = { _: resolve.value }
  resolve.chainWithTitle = (chainArgs: ChainWithTitleArgs) => ({
    _: resolve.value,
    __TITLE: chainArgs.title,
    __BREADCRUMBTITLE: chainArgs.breadCrumbTitle,
  })
  resolve.chainMap = <M = ANY>(map: M) => ({ ...resolve.chain, ...map })
  resolve.chainMapAuto = <M = ANY>(
    createMap: (prefix: string) => M,
    chainAutoArgs?: ChainWithTitleArgs
  ) => ({
    ...(chainAutoArgs ? resolve.chainWithTitle(chainAutoArgs) : resolve.chain),
    ...createMap(resolve.value.slice(1)),
  })
  return resolve
}

// eslint-disable-next-line consistent-return
export const getRouteTitle = memoize((route: string): ReactNode | undefined => {
  const mapsArr: $TSFixMe = [routingMap]
  let skipNext = false
  let routeParts = route.split('/')
  routeParts = routeParts.filter((r) => r)

  // eslint-disable-next-line consistent-return
  const findSubNameKey = (map: $TSFixMe, routePart: string) => {
    // for cases like 'edit/:id
    // where we have double function, and the other function is another route.
    for (const key in map) {
      if (
        key.slice(0, routePart.length + 1) === `${routePart}/` &&
        typeof map[key] === 'function'
      ) {
        return map[key](':id')
      }
    }
  }

  routeParts.forEach((routePart) => {
    const lastMap = mapsArr.at(-1)
    const newMap = lastMap[routePart]

    if (skipNext) {
      skipNext = false
      return
    }

    if (newMap) {
      mapsArr.push(newMap)
    } else if (findSubNameKey(lastMap, routePart)) {
      mapsArr.push(findSubNameKey(lastMap, routePart))
      skipNext = true
    } else if (lastMap[':id'] && typeof lastMap[':id'] === 'function') {
      mapsArr.push(lastMap[':id'](':id'))
    } else {
      // eslint-disable-next-line guard-for-in
      for (const key in lastMap) {
        if (typeof lastMap[key] === 'function') {
          mapsArr.push(lastMap[key](':id'))
          break
        }
      }
    }
  })

  for (const map of mapsArr.reverse()) {
    // eslint-disable-next-line no-underscore-dangle
    if (map.__TITLE) {
      // eslint-disable-next-line no-underscore-dangle
      return map.__TITLE
    }
  }
})
