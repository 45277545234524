import React from 'react'
import { Button, ButtonProps as ButtonBaseProps } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  TX_ADD,
  TX_CANCEL,
  TX_CREATE,
  TX_DELETE,
  TX_EDIT,
  TX_SUBMIT,
  TX_SUBMITTING,
  TX_VIEW,
} from '../../transifex'
import { WHTooltip, WHTooltipProps } from '../Tooltip'

export type AntdButtonProps = ButtonBaseProps & {
  iconOnly?: boolean
  /** tooltip props when iconOnly is true
   * title will also be overwritten if passed
   */
  toolTipProps?: WHTooltipProps
}

export const AntdButton = ({
  iconOnly,
  children,
  toolTipProps,
  ...rest
}: AntdButtonProps) => {
  return (
    <WHTooltip title={iconOnly && children} {...toolTipProps}>
      {/* eslint-disable-next-line react/no-children-prop */}
      <Button {...rest} children={!iconOnly && children} />
    </WHTooltip>
  )
}

export const ButtonSubmit = ({
  loading,
  children,
  htmlType = 'submit',
  ...restProps
}: AntdButtonProps) => {
  const title = loading ? TX_SUBMITTING : TX_SUBMIT
  const childernV = children || title

  return (
    <AntdButton
      icon={<CheckOutlined />}
      type="primary"
      htmlType={htmlType}
      loading={loading}
      {...restProps}
    >
      <>{childernV}</>
    </AntdButton>
  )
}

export const ButtonCancel = ({
  children = TX_CANCEL,
  ...restProps
}: AntdButtonProps) => {
  return (
    <AntdButton icon={<CloseOutlined />} type="default" {...restProps}>
      <>{children}</>
    </AntdButton>
  )
}

export const ButtonAdd = ({
  children = TX_ADD,
  ...restProps
}: AntdButtonProps) => {
  return (
    <AntdButton icon={<PlusOutlined />} type="default" {...restProps}>
      <>{children}</>
    </AntdButton>
  )
}

export const ButtonCreate = ({
  children = TX_CREATE,
  ...restProps
}: AntdButtonProps) => {
  return (
    <AntdButton icon={<PlusOutlined />} type="default" {...restProps}>
      <>{children}</>
    </AntdButton>
  )
}

export const ButtonDelete = ({
  children = TX_DELETE,
  iconOnly,
  softDelete,
  ...restProps
}: AntdButtonProps & { softDelete?: boolean }) => {
  return (
    <AntdButton
      icon={iconOnly && softDelete ? <CloseOutlined /> : <DeleteOutlined />}
      type="text"
      danger
      iconOnly={iconOnly}
      {...restProps}
    >
      <>{children}</>
    </AntdButton>
  )
}

export const ButtonEdit = ({
  children = TX_EDIT,
  iconOnly,
  ...restProps
}: AntdButtonProps) => {
  return (
    <AntdButton
      icon={<EditOutlined />}
      type="default"
      iconOnly={iconOnly}
      {...restProps}
    >
      <>{children}</>
    </AntdButton>
  )
}

export const ButtonView = ({
  children = TX_VIEW,
  iconOnly,
  ...restProps
}: AntdButtonProps) => {
  return (
    <AntdButton
      icon={iconOnly && <EyeOutlined />}
      type="default"
      iconOnly={iconOnly}
      {...restProps}
    >
      <>{children}</>
    </AntdButton>
  )
}

/** @deprecated
 * Use Button Add with iconOnly variant
 */
export const ButtonCompactAdd = (restProps: AntdButtonProps) => {
  return <Button icon={<PlusOutlined />} type="primary" {...restProps} />
}
