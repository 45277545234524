import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdPaperFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.77629 4.91788C4.83152 4.91788 4.8763 4.8731 4.87629 4.81786L4.8754 0.973776C4.87538 0.883486 4.76517 0.839457 4.70294 0.904878L1.04642 4.74896C0.985843 4.81264 1.03098 4.91788 1.11887 4.91788H4.77629Z" />
      <path d="M5.22376 0.501007C5.16853 0.501007 5.12376 0.545779 5.12376 0.601007V5.07827C5.12376 5.13349 5.07898 5.17827 5.02376 5.17827H0.767358C0.71213 5.17827 0.667358 5.22304 0.667358 5.27826V13.399C0.667358 13.4542 0.71213 13.499 0.767358 13.499H9.23268C9.28791 13.499 9.33268 13.4542 9.33268 13.399V0.601007C9.33268 0.545779 9.28791 0.501007 9.23268 0.501007H5.22376Z" />
    </svg>
  )
})
