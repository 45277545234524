import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdHardMetalFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 6 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 13.132C6 13.1872 5.95523 13.232 5.9 13.232H0.0999999C0.0447714 13.232 0 13.1872 0 13.132V2.18651C0 2.13128 0.0447715 2.08651 0.1 2.08651H5.9C5.95523 2.08651 6 2.13128 6 2.18651V13.132ZM4.00026 3.77984H3.20068V3.23387H2.80089V3.77984H2.00131V4.09062H2.80089V4.66178H3.20068V4.09062H4.00026V3.77984ZM4.00026 11.7996H2.00052V12.0852H4.00026V11.7996Z" />
      <path d="M1.59994 0.86799C1.59994 0.812762 1.64472 0.76799 1.69994 0.76799H4.30005C4.35528 0.76799 4.40005 0.812762 4.40005 0.86799V1.80037H1.69994C1.64472 1.80037 1.59994 1.7556 1.59994 1.70037V0.86799Z" />
    </svg>
  )
})
