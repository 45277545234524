import { pathJoin } from '../../../../utils'

export const createContainerRouteMap = (prefix: string) => ({
  configuration: pathJoin(prefix)('configuration').chain,
  routes: pathJoin(prefix)('route').chain,
  'service-log': pathJoin(prefix)('service-log').chain,
  'event-log': pathJoin(prefix)('event-log').chain,
  tickets: pathJoin(prefix)('tickets').chain,
  property: pathJoin(prefix)('property').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  'property-group': pathJoin(prefix)('property-group').chain,
})
