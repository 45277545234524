import { useEffect } from 'react'
import create from 'zustand'
import history from '../../history'

export const HISTORY_STORE_ACTIONS_KEY = 'HISTORY_STORE_ACTIONS'
export const RESET_HISTORY = 'RESET_HISOTRY'

export interface HistoryStoreState {
  stack: string[]
  commandKeyDown: boolean
  push: (pathname: string) => void
  pop: (pathname: string) => void
  replace: (pathname: string) => void
  goBack: (n?: number) => void
  reset: (pathname: string) => void
}

const useHistoryStore = create<HistoryStoreState>((set) => ({
  // eslint-disable-next-line no-restricted-globals
  stack: [location.pathname],
  commandKeyDown: false,

  push: (pathname) =>
    set(({ stack }) => ({
      stack: [...stack, pathname],
    })),

  pop: (pathname) =>
    set(({ stack }) => {
      return {
        stack: stack?.length <= 1 ? [...stack, pathname] : stack.slice(0, -1),
      }
    }),

  replace: (pathname) =>
    set(({ stack }) => ({
      stack: [...stack.slice(0, -1), pathname],
    })),

  goBack: (n = 0) => {
    set(({ stack }) => {
      const pathname = stack.slice(-2 - n)[0]
      history.push(pathname)
      return { stack: stack.slice(0, -1 - n) }
    })
  },

  reset: (pathname) =>
    set({
      stack: [pathname],
    }),
}))

const useHistoryStoreInit = () => {
  const pushHistoryItem = useHistoryStore((store) => store.push)
  const popHistoryItem = useHistoryStore((store) => store.pop)
  const replaceHistoryItem = useHistoryStore((store) => store.replace)
  const resetHistory = useHistoryStore((store) => store.reset)

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      useHistoryStore.setState({
        commandKeyDown: event.ctrlKey || event.metaKey,
      })
    }

    const keyUpHandler = (event: KeyboardEvent) => {
      useHistoryStore.setState({
        commandKeyDown: event.ctrlKey || event.metaKey,
      })
    }
    const onFocusChange = () => {
      useHistoryStore.setState({
        commandKeyDown: false,
      })
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('keyup', keyUpHandler)
    window.addEventListener('focus', onFocusChange)

    const unlisten = history.listen((p) => {
      const { stack } = useHistoryStore.getState()
      const lastItem = stack[stack.length - 1]

      // This handles the case we go to the same route from current route
      // E.G we go from /dashboard -> /dashboard#test
      if (lastItem === p.pathname) {
        return
      }

      if (
        (
          (p?.state as Record<string, unknown>)?.[
            HISTORY_STORE_ACTIONS_KEY
          ] as string[]
        )?.includes(RESET_HISTORY)
      ) {
        resetHistory(p.pathname)
        return
      }

      switch (history.action) {
        case 'POP':
          popHistoryItem(p.pathname)
          break
        case 'PUSH':
          pushHistoryItem(p.pathname)
          break
        case 'REPLACE':
          replaceHistoryItem(p.pathname)
          break
        default:
          break
      }
    })

    return () => {
      unlisten()
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('keyup', keyUpHandler)
      window.removeEventListener('focus', onFocusChange)
    }
  }, [popHistoryItem, pushHistoryItem, replaceHistoryItem, resetHistory])
}

export { useHistoryStore, useHistoryStoreInit }
