import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'antd'
import useDimensions from 'react-cool-dimensions'
import BraftEditor, {
  BraftEditorProps,
  ControlType,
  EditorState,
} from 'braft-editor'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import 'braft-editor/dist/index.css'
import styled from 'styled-components'
import classnames from 'classnames'
import { createPrefixer } from 'src/utils/createPrefixer'

import * as config from './config'
import useLanguageParser from './useLanguageParser'
import { CssScroller } from '../CssScroller'
import LeftContent from './Content/LeftContent'
import RightContent from './Content/RightContent'
import { Typography } from '../Typography'
import { TX_TEXT_EDITOR_IS_IN_FULLSCREEN_MODE } from '../../transifex'

export const WIDE_BREAKPOINT = 'Wide'

const p = createPrefixer('wh-UI-RichTextEditor-')

const classes = {
  wrapper: p`wrapper`,
}

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  // There is a bug in main RichTextEditor of overflow which is solved by adding overflow hidden.
  overflow: hidden;

  & .bf-controlbar {
    /* display: flex; */
    /* align-items: center; */
    box-shadow: unset;
  }
  & .bf-controlbar [data-title]:before {
    color: #fff;
  }
  & .bf-controlbar [data-title]:after {
    background-color: #fff;
    color: black;
  }
  & .bf-controlbar .font-icon,
  .bf-controlbar .font-icon:active,
  .bf-controlbar .font-icon:hover {
    background-color: transparent !important;
    color: rgba(38, 38, 38, 1) !important;
    cursor: default;
  }
  & .bf-controlbar [data-title='Font size'] {
    background-color: #fff !important;
    color: #000;
    width: 200px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
  }
  & .bf-controlbar [data-title='Clear'],
  .bf-controlbar .expand-collapse {
    float: right;
    &:hover {
      background-color: inherit !important;
    }
  }
  & .bf-controlbar .control-item.button,
  & .bf-dropdown .dropdown-handler {
    color: rgba(38, 38, 38, 1);
  }
  & .bf-controlbar .control-item.button:hover {
    background-color: rgba(230, 247, 255, 1);
  }
  & .bf-dropdown .dropdown-handler:hover {
    background-color: rgba(230, 247, 255, 1);
  }
  & .bf-dropdown.active .dropdown-handler {
    background-color: rgba(230, 247, 255, 1);
    color: rgba(24, 144, 255, 1);
  }
  & .bf-controlbar .control-item.button.active {
    color: rgba(24, 144, 255, 1);
  }
  & .component-wrapper {
    height: 100%;
  }

  .btn-float-right {
    float: right;
  }

  .bf-content {
    ${CssScroller}
  }

  .bf-dropdown .dropdown-handler {
    &:hover {
      background-color: #fafafa;
    }
  }

  &.disabled {
    .bf-controlbar {
      opacity: 0.5;
    }
  }

  &.expanded {
    position: fixed;
    top: 15%;
    right: 15%;
    width: 70vw;
    height: 70vh;
    padding: 1rem;
    background: #f5f5f5;
    // 1202 is used because the sidebar has the z-index 1200 and the div to close
    // the full screen editor needs to have a higher zindex than the sidebar as well
    z-index: 1202;
    border-radius: 5px;
  }
`

export type TRichTextEditorProps = Omit<
  BraftEditorProps,
  'language' | 'onChange' | 'readOnly'
> & {
  // only supports html format.
  onChange?: (val: string, editorState: EditorState) => void
  // disabled state
  disabled?: boolean
  variables?: ItemType[]
  /** default to true */
  showExpand?: boolean
  /** ignoreEmptyHTML
   * default to false.
   * Pass empty string if there is no text in the html
   */
  ignoreEmptyHTML?: boolean
  autoFocus?: boolean
}

const RichTextEditor: React.FC<TRichTextEditorProps> & {
  classes: typeof classes
} = ({
  value,
  onChange,
  disabled = false,
  variables,
  showExpand = true,
  ignoreEmptyHTML,
  autoFocus,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false)
  const translated = useLanguageParser()
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(value ?? null)
  )
  const editorRef = useRef<$TSFixMe>(null)

  useEffect(() => {
    if (autoFocus) {
      editorRef?.current?.requestFocus()
    }
  }, [autoFocus])

  const { observe, currentBreakpoint } = useDimensions({
    breakpoints: { [WIDE_BREAKPOINT]: 800 },
    updateOnBreakpointChange: true,
  })

  useEffect(() => {
    if (onChange) {
      const newInit = BraftEditor.createEditorState(value ?? null)
      if (editorState.toHTML() !== newInit.toHTML()) {
        if (ignoreEmptyHTML) {
          const hasValue = editorState.toText().length
          onChange?.(hasValue ? editorState.toHTML() : ``, editorState)
        } else {
          onChange?.(editorState.toHTML(), editorState)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState])

  useEffect(() => {
    const newInit = BraftEditor.createEditorState(value ?? null)
    if (editorState.toHTML() !== newInit.toHTML()) {
      setEditorState(newInit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const controls: ControlType[] = [
    {
      key: 'content',
      type: 'component',
      component: (
        <Row style={{ height: '100%' }} align="middle" justify="space-between">
          <Col>
            <LeftContent
              editorState={editorState}
              setEditorState={setEditorState}
              wide={currentBreakpoint === WIDE_BREAKPOINT}
            />
          </Col>
          <Col>
            <RightContent
              editorState={editorState}
              setEditorState={setEditorState}
              expanded={expanded}
              setExpanded={setExpanded}
              variables={variables}
              showExpand={showExpand}
            />
          </Col>
        </Row>
      ),
    },
  ]

  const closeFullScreen = (event: { keyCode: number }) => {
    if (event.keyCode === 27) {
      setExpanded(false)
    }
  }

  return (
    <>
      {expanded && (
        <>
          <div
            style={{
              height: '128px',
              width: '100%',
              textAlign: 'center',
              margin: 'auto',
              lineHeight: '128px',
            }}
          >
            <Typography variant="header-24">
              {TX_TEXT_EDITOR_IS_IN_FULLSCREEN_MODE}
            </Typography>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              bottom: 0,
              top: 0,
              right: 0,
              left: 0,
              // the sidebar's zindex is 1200, which is why we use 1201 here.
              zIndex: 1201,
              background: '#000000',
              opacity: 0.5,
            }}
            onClick={() => {
              setExpanded(false)
            }}
          />
        </>
      )}
      <Wrapper
        ref={observe}
        className={classnames(classes.wrapper, { disabled, expanded })}
        onKeyDown={closeFullScreen}
        id="rich-text-editor"
      >
        <BraftEditor
          ref={editorRef}
          value={editorState}
          textAligns={['left', 'center', 'right']}
          controlBarStyle={
            expanded ? config.expandedControlBarStyles : config.controlBarStyles
          }
          readOnly={disabled}
          contentStyle={{
            ...config.contentStyles,
            height: expanded ? 'calc(70vh - 48px - 32px)' : 80,
            width: expanded ? 'calc(70vw - 32px)' : 'inherit',
            background: '#fff',
          }}
          controls={controls}
          language={() => translated}
          onChange={setEditorState}
          {...props}
        />
      </Wrapper>
    </>
  )
}

RichTextEditor.classes = classes
export { RichTextEditor }
