import React from 'react'
import moment, { Moment } from 'moment'
import { WHTooltip } from '../Tooltip'
// eslint-disable-next-line import/no-cycle
import { NO_DATA } from '../table-kit'

export type TDateFormatType = 'lll' | 'll' | 'LT' | 'LTS'

type TDateComponentProps = {
  format?: TDateFormatType
  time: string | Moment | undefined
  inputFormat?: string
  tooltip?: React.ReactNode
}

export const dateTooltipFormatMap: Record<TDateFormatType, string | undefined> =
  {
    lll: undefined,
    ll: 'lll',
    LT: 'LTS',
    LTS: undefined,
  }

export const DateComponent = ({
  format = 'lll',
  time,
  inputFormat,
  tooltip,
}: TDateComponentProps) => {
  if (!time) {
    return <WHTooltip title={tooltip}>{NO_DATA}</WHTooltip>
  }

  return (
    <WHTooltip
      title={
        tooltip ||
        (dateTooltipFormatMap[format]
          ? moment(time, inputFormat).format(dateTooltipFormatMap[format])
          : '')
      }
    >
      {moment(time, inputFormat).format(format)}
    </WHTooltip>
  )
}
