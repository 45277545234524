import React, { createContext, useContext, PropsWithChildren } from 'react'
import { FormItem } from 'formik-antd'

import { FormItemCustomProps, IsRequiredProps } from './types'
import type * as FormikAntdFormItemTypes from './types'

export type { FormikAntdFormItemTypes }

const FormItemIsRequiredContext = createContext<IsRequiredProps>(
  {} as IsRequiredProps
)

const useIsRequiredContext = () => {
  const store = useContext(FormItemIsRequiredContext)

  if (!store) {
    throw new Error(
      'Cannot use `useIsRequiredContext` outside of a FormItemIsRequiredContext'
    )
  }

  return store
}

const IsRequiredProvider = <T extends string>({
  isRequired,
  children,
}: PropsWithChildren<IsRequiredProps<T>>) => {
  return (
    <FormItemIsRequiredContext.Provider
      value={{ isRequired: isRequired as IsRequiredProps['isRequired'] }}
    >
      {children}
    </FormItemIsRequiredContext.Provider>
  )
}

const FormItemCustom = <T extends string = string>({
  name,
  ...rest
}: FormItemCustomProps<T>): JSX.Element => {
  const { isRequired } = useIsRequiredContext()
  return <FormItem name={name} required={isRequired(name)} {...rest} />
}

export const FormikAntdFormItem = {
  IsRequiredProvider,
  FormItemCustom,
}
