import { cookies, COOKIES_CONST } from 'src/Cookies'

const { host, protocol } = window.location

let apiUrlCookie = cookies.get(COOKIES_CONST.API_URL)

export const isVercel = Boolean(window.location.hostname.includes('vercel.app'))

if (!apiUrlCookie && isVercel) {
  const hostname =
    // eslint-disable-next-line no-alert
    window.prompt('Enter api hostname') || process.env.REACT_APP_API_URL

  cookies.set(COOKIES_CONST.API_URL, hostname)
  apiUrlCookie = hostname
}

export const baseUrl = `${
  process.env.REACT_APP_API_SSL === 'true' ? 'https:' : protocol
}//${apiUrlCookie || process.env.REACT_APP_API_URL || host}`
