import React from 'react'
import styled from 'styled-components'
import { CodeOutlined } from '@ant-design/icons'
import { Dropdown, DropdownProps, Menu, MenuProps } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import ControlBarButton from './ControlBarButton'
import { CssScroller } from '../../CssScroller'
import { TX_INSERT_VARIABLES } from '../../../transifex'

const DropdownOverlayClassname = ({
  className,
  ...props
}: DropdownProps & { className?: string }) => (
  <Dropdown {...props} overlayClassName={className} />
)

const ScrollDropdown = styled(DropdownOverlayClassname)`
  .ant-dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
    ${CssScroller}
  }
`

type VariableProps = {
  variables: ItemType[]
  onVariableClick?: MenuProps['onClick']
}

const Variables = ({ variables, onVariableClick }: VariableProps) => {
  return (
    <ScrollDropdown
      placement="bottomRight"
      overlay={<Menu onClick={onVariableClick} items={variables} />}
      trigger={['click']}
    >
      <ControlBarButton tooltip={TX_INSERT_VARIABLES} icon={<CodeOutlined />} />
    </ScrollDropdown>
  )
}

export default Variables
