import React, { FC } from 'react'
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'
import { TDriverDriverType, TDriverDriverTypeLabelMap } from 'src/api/consts'
import { array } from 'src/utils/array'
import { useRoutingHistory } from 'src/reducers/routing/child-router-factory'
import useDelayQuery from 'src/hooks/useDelayQuery'
import { PopupContentProps } from '..'
import InfoCard from '../components/InfoCard'
import { GET } from './api'
import {
  GetDriverInfoPopover,
  GetDriverInfoPopoverVariables,
} from './__generated__/GetDriverInfoPopover'

const DriverPopup: FC<PopupContentProps> = ({ id }) => {
  const [history, { routingMap }] = useRoutingHistory()
  const { data, loading } = useDelayQuery<
    GetDriverInfoPopover,
    GetDriverInfoPopoverVariables
  >(
    GET,
    {
      variables: {
        id,
      },
    },
    300
  )

  return (
    <InfoCard
      title={`${data?.driver?.user.firstName} ${data?.driver?.user.lastName}`}
      avatarProps={{
        src: data?.driver?.user.logo,
      }}
      headIcon={<UserOutlined />}
      headText={<T _str="Drivers" />}
      data={array(
        !!data?.driver?.user.phoneNumber && {
          icon: <PhoneOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />,
          label: data?.driver?.user.phoneNumber,
        },
        !!data?.driver?.user.email && {
          icon: <MailOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />,
          label: data?.driver?.user.email,
        }
      )}
      tags={
        data?.driver?.driverType
          ? [
              {
                label:
                  TDriverDriverTypeLabelMap[
                    data?.driver?.driverType as TDriverDriverType
                  ],
              },
            ]
          : undefined
      }
      onView={() => {
        history.push(
          routingMap.app['fleet-management'].drivers[':driverId'](id)._
        )
      }}
      loading={loading}
    />
  )
}

export default DriverPopup
