import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdFoodAndDrinkFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.47465 4.53656C6.47465 4.48133 6.42988 4.43656 6.37465 4.43656H0.826807C0.771578 4.43656 0.726807 4.48133 0.726807 4.53656V13.3097C0.726807 13.365 0.771578 13.4097 0.826807 13.4097H6.37465C6.42988 13.4097 6.47465 13.365 6.47465 13.3097V4.53656Z" />
      <path d="M7.71386 0.690271C7.71386 0.635043 7.66909 0.590271 7.61386 0.590271H2.05285C1.99763 0.590271 1.95285 0.635043 1.95285 0.690271V1.62733C1.95285 1.68256 1.99763 1.72733 2.05285 1.72733H7.61386C7.66909 1.72733 7.71386 1.68256 7.71386 1.62733V0.690271Z" />
      <path d="M6.47463 4.14483C6.47463 4.14113 6.47762 4.13814 6.48132 4.13814H6.48367C6.48705 4.13814 6.49015 4.13625 6.4917 4.13325L7.51554 2.15789C7.55004 2.09132 7.50173 2.01187 7.42676 2.01187H2.00109C1.96487 2.01187 1.93148 2.03146 1.91381 2.06307L0.829367 4.00273C0.792099 4.06938 0.840283 4.15153 0.916651 4.15153H6.46793C6.47163 4.15153 6.47463 4.14853 6.47463 4.14483Z" />
      <path d="M7.15912 3.58497C7.11076 3.67835 7.22082 3.77352 7.30625 3.71218L8.86367 2.59383C8.93002 2.54619 8.91435 2.4434 8.83681 2.41769L8.00196 2.14084C7.95529 2.12537 7.9043 2.14611 7.88168 2.18977L7.15912 3.58497Z" />
      <path d="M6.90276 13.0776C6.90276 13.1592 6.99513 13.2065 7.06127 13.1587L9.23162 11.593C9.25767 11.5742 9.27311 11.544 9.27311 11.5119V2.87291C9.27311 2.79147 9.18095 2.74418 9.11479 2.79168L6.94445 4.34978C6.91828 4.36857 6.90276 4.3988 6.90276 4.43101V13.0776Z" />
    </svg>
  )
})
