import React, { ReactNode, useCallback, useMemo } from 'react'
import { Table, TableColumnProps, TableProps } from 'antd'
import styled from 'styled-components'
import { usePrevious } from 'src/hooks'
import {
  useTreeFilterAndOrder,
  UseTreeFilterAndOrderArgs,
  useTreeFilterAndOrderSimple,
} from '../TreeFilterAndOrder'
import { CssScroller } from '../CssScroller'

const StyledTable = styled(Table)`
  /* We are adding custom styling to handle the overflow of the table. We don't use scroll.y  
     because that separates the header of the table from the body and then scroll.x === max-content doesn't work well for the header titles.
     See: https://github.com/ant-design/ant-design/issues/40101
     To avoid that, we just avoid using the scroll.y The following styling will work better without separating the header from the body.

   */

  &&&& {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        .ant-table {
          flex: auto;
          overflow: hidden;

          .ant-table-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            .ant-table-header {
              flex: none;
            }

            .ant-table-body {
              flex: auto;
              ${CssScroller}
            }
          }
        }

        .ant-table-pagination {
          flex: none;
        }
      }
    }

    .ant-table-content {
      overflow: auto !important;
      height: 100%;
      .ant-table-thead {
        position: sticky;
        top: 0;
        background: white;
        z-index: 800;
      }
      .ant-table-cell {
        padding: 8px 16px;
      }
    }
  }

  /* Removing box shadows */
  .ant-table-ping-left:not(.ant-table-has-fix-left)
    > .ant-table-container::before {
    box-shadow: none !important;
  }

  .ant-table-cell-fix-right-first::after,
  .ant-table-cell-fix-right-last::after {
    border-right: 1px solid #f0f0f0;
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first::after,
  .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: none !important;
  }

  .ant-table-cell-fix-left-first::after,
  .ant-table-cell-fix-left-last::after {
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
` as typeof Table

export type SortKey = 'ascend' | 'descend'

export type UseTableUICustomArgs<
  Data,
  Actions extends string,
  ExtendedFuns extends Record<string, unknown> = Record<string, unknown>
> = {
  on: Record<Actions, (data: Data) => void> & {
    customRender?: (data: Data) => ReactNode
  }
  extended: ExtendedFuns
  sorting?: {
    sortField: keyof Data
    sortOrder?: SortKey
    fields: Array<keyof Data>
  }
  selectionColumnPosition?: number
  expansionColumnPosition?: number
  hideColumns?: Array<keyof Data | '__actions'>
  actionColumnProps?: Omit<TableColumnProps<Data>, 'render' | 'children'>
  /**
   * @deprecated Not used $TSFixMemore
   */
  fillWidth?: boolean
}

type ColumnRenderMapResult<DataCell, ColumnKey extends string> = Record<
  ColumnKey,
  TableColumnProps<DataCell> & {
    /**
     * @default 500 - max-width of the cell.
     * Note: if the table overall width is more then the max-width of all of the columns, then it will be ignored
     * This property can also be overwritten by onCell style.maxWidth property
     */
    maxWidth?: number
    /**
     * @deprecated Not used $TSFixMemore - width is always set to auto
     */
    width?: TableColumnProps<DataCell>['width']
  }
>

export type TTableColumnsTreeFilterAndOrderProps<ColumnKey extends string> =
  Omit<UseTreeFilterAndOrderArgs<ColumnKey>, 'columns' | 'key'> & {
    key?: string
    /** default to true */
    usePersistent?: boolean
  }

export type CreateTableUIStatefulColumnsArgs<
  DataCell extends Record<string, unknown>,
  ColumnKey extends string,
  Actions extends string,
  ExtendedFuns extends Record<string, unknown> = Record<string, unknown>
> = {
  columnsRenderMap: (
    args: ExtendedFuns
  ) => ColumnRenderMapResult<DataCell, ColumnKey>
  actionsRender?: (args: {
    on: UseTableUICustomArgs<DataCell, Actions, ExtendedFuns>['on']
  }) => TableColumnProps<DataCell>['render']
  tableColumnsTreeFilterAndOrderProps: TTableColumnsTreeFilterAndOrderProps<ColumnKey>
}

export const createTableUIStatefulColumns = <
  DataCell extends Record<string, unknown>,
  ColumnKey extends string,
  Actions extends string,
  ExtendedFuns extends Record<string, unknown> = Record<string, unknown>
>(
  props: CreateTableUIStatefulColumnsArgs<
    DataCell,
    ColumnKey,
    Actions,
    ExtendedFuns
  >
) => {
  type TableUIProps = Omit<TableProps<DataCell>, 'columns' | 'scroll'> &
    UseTableUICustomArgs<DataCell, Actions, ExtendedFuns> & {
      tableColumnsTreeFilterAndOrderProps?: Partial<
        Omit<TTableColumnsTreeFilterAndOrderProps<ColumnKey>, 'usePersistent'>
      >
      /**
       * @deprecated Not used $TSFixMemore.
       * It handles custom styling built-in.
       *
       */
      scroll?: TableProps<DataCell>['scroll']
    }
  const {
    columnsRenderMap: columnsRenderMapFnMaybe,
    actionsRender,
    tableColumnsTreeFilterAndOrderProps,
  } = props

  const { key: treeFilterKey, usePersistent = true } =
    tableColumnsTreeFilterAndOrderProps

  const useColumnsToggleHook = usePersistent
    ? useTreeFilterAndOrder
    : useTreeFilterAndOrderSimple

  const useTableUI = ({
    on,
    extended,
    tableColumnsTreeFilterAndOrderProps:
      tableColumnsTreeFilterAndOrderPropsExtended,
    sorting,
    selectionColumnPosition,
    expansionColumnPosition,
    hideColumns = [],
    pagination,
    actionColumnProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fillWidth,
    dataSource,
    loading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scroll: ignored,
    ...rest
  }: TableUIProps) => {
    const prevData = usePrevious(dataSource)
    const columnsRenderMap = useMemo(
      () => columnsRenderMapFnMaybe(extended || ({} as ExtendedFuns)),
      [extended]
    )

    /* tree-filter */
    const filterAndOrderCols = useMemo(
      () =>
        (Object.keys(columnsRenderMap) as ColumnKey[]).reduce((acc, key) => {
          const val = columnsRenderMap[key]
          if (hideColumns?.includes(key)) return acc
          return {
            ...acc,
            [key]: {
              title: val.title,
              key,
            },
          }
        }, {} as UseTreeFilterAndOrderArgs<ColumnKey>['columns']),
      [columnsRenderMap, hideColumns]
    )

    const { key: treeFilterKeyExtended } =
      tableColumnsTreeFilterAndOrderPropsExtended ?? {}

    if (!treeFilterKey && !treeFilterKeyExtended && usePersistent)
      throw Error(`No TreeFilter Key Provided with usePersistent true. 
        Either provide a key or set usePersistent to false.
      `)

    const {
      state: filterAndOrderColumns,
      jsx: { toggler },
    } = useColumnsToggleHook<ColumnKey>({
      columns: filterAndOrderCols,
      ...tableColumnsTreeFilterAndOrderProps,
      ...tableColumnsTreeFilterAndOrderPropsExtended,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      key: treeFilterKeyExtended ?? treeFilterKey!,
      hideColumns: hideColumns as ColumnKey[],
    })

    /* sorting */

    const getSort = useCallback(
      () =>
        (field: ColumnKey): TableColumnProps<DataCell> =>
          sorting?.fields.includes(field)
            ? {
                sorter: !!sorting,
                sortOrder:
                  (sorting?.sortField === field && sorting.sortOrder) ||
                  undefined,
              }
            : {},
      [sorting]
    )

    const columns: TableColumnProps<DataCell>[] = useCallback(() => {
      const cols: TableColumnProps<DataCell>[] = [
        ...filterAndOrderColumns.map(
          (key): TableColumnProps<DataCell> & { dataIndex: string } => {
            return {
              ...getSort()(key),
              ...columnsRenderMap[key],
              dataIndex: key,
              width: 'auto',
              onCell: (...a) => {
                const { style, ...tf } =
                  columnsRenderMap[key].onCell?.(...a) || {}
                const maxWidth = columnsRenderMap[key].maxWidth ?? 500
                return { ...tf, style: { maxWidth, ...style } }
              },
            }
          }
        ),
      ].filter(({ dataIndex }) => !hideColumns.includes(dataIndex))

      if (selectionColumnPosition !== undefined) {
        cols.splice(selectionColumnPosition, 0, Table.SELECTION_COLUMN)
      }
      if (expansionColumnPosition !== undefined) {
        cols.splice(expansionColumnPosition, 0, Table.EXPAND_COLUMN)
      }

      if (actionsRender && !hideColumns.includes('__actions')) {
        cols.push({
          title: ``,
          ...actionColumnProps,
          width: 'auto',
          render: actionsRender?.({ on }),
          fixed: 'right',
        })
      }

      return cols
    }, [
      filterAndOrderColumns,
      selectionColumnPosition,
      expansionColumnPosition,
      getSort,
      columnsRenderMap,
      hideColumns,
      on,
      actionColumnProps,
    ])()

    // When it's loading and there is no data, we want to show the previous data for better UX transition effect.
    const ds = loading && !dataSource?.length ? prevData : dataSource

    return {
      table: (
        <StyledTable
          size="small"
          {...rest}
          loading={loading}
          dataSource={ds}
          scroll={{
            x: 'max-content',
          }}
          columns={columns}
          pagination={
            pagination === false
              ? false
              : {
                  ...pagination,
                  showQuickJumper: true,
                  showSizeChanger: true,
                }
          }
        />
      ),
      toggler,
    }
  }

  return {
    useTableUI,
  }
}
