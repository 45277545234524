import { T } from '@transifex/react'
import React from 'react'
import { pathJoin } from '../../../utils'

export const createDashboardsRoutesMap = (prefix: string) => ({
  'view-test': pathJoin(prefix)('view-test').chainWithTitle({
    title: <T _str="Dashboard View" />,
    breadCrumbTitle: <T _str="View" />,
  }),
  'edit-test': pathJoin(prefix)('edit-test').chainWithTitle({
    title: <T _str="Dashboard Edit" />,
    breadCrumbTitle: <T _str="Edit" />,
  }),
  'browse-test': pathJoin(prefix)('browse-test').chainWithTitle({
    title: <T _str="Dashboard Browse" />,
    breadCrumbTitle: <T _str="Browse" />,
  }),
})
