import { ArrowRightOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import React, { ReactNode } from 'react'
import { TX_FROM, TX_TO } from '../../transifex'

export type TFromToProps = {
  from: ReactNode
  to: ReactNode
  icon?: ReactNode
  short?: boolean
  /** default to true
   * Will break to new line.
   */
  wrap?: boolean
}

export const FromTo = ({
  from,
  to,
  icon = <ArrowRightOutlined style={{ color: 'gray' }} />,
  short,
  wrap = true,
}: TFromToProps) => (
  <Space size={6} wrap={wrap}>
    {!short && from && TX_FROM}
    {from}
    {from && to && icon} {to && !short && TX_TO}
    {to}
  </Space>
)
