import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdSquareFilled = createAntdIcon(() => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
    </svg>
  )
})
