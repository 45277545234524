import produce from 'immer'
import { routingMap } from './routing/routing-map'
import { setRoles } from './routing/utils'

const initialSettings = {
  isSidebarOpened: true,
  pageTitle: '',
  user: null,
  activeProjects: [],
  selectedContainerFromDashboard: null,
  allContainersQuery: {
    last: undefined,
    orderBy: undefined,
    q: undefined,
  },
  allDriversQuery: {
    order: undefined,
    orderBy: undefined,
    iconSize: undefined,
  },
  allDepotsQuery: {
    order: undefined,
    orderBy: undefined,
    iconSize: undefined,
  },
  allVehiclesQuery: {
    order: undefined,
    orderBy: undefined,
    iconSize: undefined,
  },
  allVehicleTypesQuery: {
    order: undefined,
    orderBy: undefined,
  },
  allRoutesQuery: {
    order: undefined,
    orderBy: undefined,
    pageSize: undefined,
  },
  variables: {
    VHRed: '#E25656',
    clusterColor: '#e09837',
    VHRedDark: '#E23D3B',
    VHGreen: '#389E74',
    VHBlueDark: '#003348',
    VHGrey: '#424242',
    VHGreyLight: '#c6c6c6',
    cLightBlue: '#EBF7FF',
    cLightgrayBr: '#eaeaea',
    cGrayInpBorder: '#E2E2E2',
    cGray: '#c6c6c6',
    cDGray: '#555',
    cTextDark: '#333',
    cEmperor: '#555555',
    noSidebarImage: false,

    // newTheme
    defaultBoxShadow: '0 2px 6px 0 rgba(0,0,0,0.14)',
    defaultFont: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    cCharcoal: '#181818',
    VHBlue: '#024559',
    buttonBlue: '#024559',
    sidebarBackgroundColor: '#024559',
    VHDarkBlue: '#092B39',
    VHLightBlue: '#6F7DA3',
    VHDarkGrey: '#939393',
    HeaderColor: '#5B90A6',
    cDarkBlue: '#313C5A',
    cSilver: '#809298',
    cSlate: '#989DAC',
    cLightGray: '#C4C7D2',
    cSnow: '#FAFAFA',
    cAntiFlashWhite: '#F0F2F7',
    chipBackgroundColor: '#F0F2F7',
    cWhite: '#ffffff',
    VHLightBlack: '#626469',
    cRichOcean: '#022631',
    VHOrange: '#DF9838',
    cOrange: '#E58E3E',
    cLightPeach: '#F8CD97',
    cLightRed: '#EC6B6B',
    cGreen: '#50B42C',
    cLemon: '#D9CC5D',
    cDarkOrange: '#D17D2F',
    cIndependence: '#474E62',
    cAquaHaze: '#EFF4F6',
    VHGreyBackground: 'rgba(63,116,140,0.2)',
    VHInputAdornment: '#757575',
  },
  routing: {
    roles: [],
    routingMap,
    init: false,
  },
}

const settings = (state = initialSettings, action: $TSFixMe) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR_OPEN':
      return {
        ...state,
        isSidebarOpened:
          action.isSidebarOpened !== undefined
            ? action.isSidebarOpened
            : !state.isSidebarOpened,
      }
    case 'SET_PAGE_TITLE':
      return {
        ...state,
        pageTitle: action.pageTitle !== undefined ? action.pageTitle : '',
      }
    case 'SET_All_CONTAINERS_QUERY':
      return {
        ...state,
        allContainersQuery:
          action.allContainersQuery !== undefined
            ? action.allContainersQuery
            : {},
      }
    case 'SET_USER_EMAIL': {
      return produce((d) => {
        // eslint-disable-next-line no-param-reassign
        if (d.user) (d.user as $TSFixMe).email = action.payload
      }, state)()
    }
    case 'SET_USER': {
      const savedActiveProjects = action.user.activeProjectsIds
      const activeProjects =
        savedActiveProjects?.length && action.user.activeProjects
          ? action.user.activeProjects.edges.map(({ node }: $TSFixMe) => node)
          : []
      const newUser = action.user !== undefined ? action.user : null
      return {
        ...state,
        user: newUser,
        activeProjects,
        userLayoutConfig: action.user?.userLayoutConfig,
        routing: {
          ...state.routing,
          roles: setRoles(newUser),
          init: true,
        },
      }
    }
    case 'SET_ROUTING':
      return {
        ...state,
        routing: {
          ...state.routing,
          ...(action.payload || {}),
        },
      }
    case `INIT_ROUTING`:
      return {
        ...state,
        routing: {
          ...state.routing,
          init: action.payload || false,
        },
      }
    case 'SET_ACTIVE_PROJECTS':
      return {
        ...state,
        activeProjects: action.newActiveProjects,
      }
    case 'SET_USER_LAYOUT_CONFIG':
      return {
        ...state,
        userLayoutConfig: {
          ...(state as $TSFixMe).userLayoutConfig,
          ...action.config,
        },
      }
    case 'SET_NEW_COMPANY_SETTINGS':
      return {
        ...state,
        variables: {
          ...state.variables,
          ...action.newCompanySettings,
        },
      }
    case 'SET_SELECTED_CONTAINER_FROM_DASHBOARD':
      return {
        ...state,
        selectedContainerFromDashboard: action.container,
      }
    case `RESET_STORE`:
      return {
        ...initialSettings,
        routing: {
          ...initialSettings.routing,
          init: true,
          roles: setRoles(initialSettings.user),
        },
      }
    default:
      return state
  }
}

export default settings
