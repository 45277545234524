import React, { useCallback, useMemo } from 'react'
import { Table, TableColumnProps, TableProps } from 'antd'
import { DEFAULT_TABLE_ACTION_COLUMN_WIDTH } from './consts'

type ExtendedProps<
  Data,
  Actions extends string,
  ExtendedFuns extends Record<string, unknown> = Record<string, unknown>
> = {
  on: Record<Actions, (data: Data) => void>
  extended: ExtendedFuns
}

type ColumnRenderMapResult<DataCell, ColumnKey extends string> = Record<
  ColumnKey,
  TableColumnProps<DataCell>
>

export type CreateTableUIStatefulColumnsSimpleArgs<
  DataCell extends Record<string, unknown>,
  ColumnKey extends string,
  Actions extends string,
  ExtendedFuns extends Record<string, unknown> = Record<string, unknown>
> = {
  columnsRenderMap: (
    args: ExtendedFuns
  ) => ColumnRenderMapResult<DataCell, ColumnKey>
  actionsRender?: (args: {
    on: ExtendedProps<DataCell, Actions, ExtendedFuns>['on']
  }) => TableColumnProps<DataCell>['render']
  orderCols?: (cols: ColumnKey[]) => ColumnKey[]
  disablePagination?: boolean
}

/**
 * @deprecated
 * Use `createTableUIStatefulColumns`
 */
export const createTableUIStatefulColumnsSimple = <
  DataCell extends Record<string, unknown>,
  ColumnKey extends string,
  Actions extends string,
  ExtendedFuns extends Record<string, unknown> = Record<string, unknown>
>(
  props: CreateTableUIStatefulColumnsSimpleArgs<
    DataCell,
    ColumnKey,
    Actions,
    ExtendedFuns
  >
) => {
  type TableUIProps = Omit<TableProps<DataCell>, 'columns'> &
    ExtendedProps<DataCell, Actions, ExtendedFuns> & {
      actionColumnProps?: Omit<
        TableColumnProps<DataCell>,
        'render' | 'children'
      >
      /** fill the width left by other columns
       * default to true
       */
      fillWidth?: boolean
    }
  const {
    columnsRenderMap: columnsRenderMapFnMaybe,
    actionsRender,
    orderCols,
    disablePagination,
  } = props

  const useTableUI = ({
    on,
    extended,
    actionColumnProps,
    fillWidth,
    ...rest
  }: TableUIProps) => {
    const columnsRenderMap = useMemo(
      () => columnsRenderMapFnMaybe(extended || ({} as ExtendedFuns)),
      [extended]
    )

    const columns: TableColumnProps<DataCell>[] = useCallback(() => {
      let colsKeys = Object.keys(columnsRenderMap) as ColumnKey[]
      if (orderCols) {
        colsKeys = orderCols(colsKeys)
      }
      const cols: TableColumnProps<DataCell>[] = [
        ...colsKeys.map((key) => ({
          ...columnsRenderMap[key],
          dataIndex: key,
        })),
      ]
      // pushing a column to just take the width for other columns
      if (fillWidth) {
        // for fluid width.
        cols.push({})
      }

      if (actionsRender) {
        cols.push({
          width: DEFAULT_TABLE_ACTION_COLUMN_WIDTH,
          ...actionColumnProps,
          title: ``,
          render: actionsRender?.({ on }),
          fixed: 'right',
        })
      }

      return cols
    }, [actionColumnProps, columnsRenderMap, fillWidth, on])()

    return {
      table: (
        <Table
          size="small"
          {...rest}
          columns={columns}
          pagination={
            !disablePagination && {
              ...rest.pagination,
              showQuickJumper: true,
            }
          }
        />
      ),
    }
  }

  return {
    useTableUI,
  }
}
