import { InfoCircleFilled } from '@ant-design/icons'
import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { ButtonCancel, ButtonSubmit } from '../Buttons'
import { Typography } from '../Typography'
import { WhIconAntdExclamationTriangleFill } from '../../wh-icons-antd'

type TVariant = 'default' | 'danger' | 'warning'

export type TConfirmProps = {
  variant?: TVariant
  title: ReactNode
  children:
    | ((args: {
        jsxCancelButton: JSX.Element
        jsxSubmitButton: JSX.Element
      }) => ReactNode)
    | ReactNode
  cancelButtonProps?: React.ComponentProps<typeof ButtonSubmit>
  submitButtonProps?: React.ComponentProps<typeof ButtonSubmit>
}

const colorMap: Record<TVariant, string> = {
  default: `#1ea7fd`,
  danger: `#FF4D4F`,
  warning: '#FFA940',
}

const iconMap: Record<TVariant, JSX.Element> = {
  default: <InfoCircleFilled />,
  danger: <WhIconAntdExclamationTriangleFill />,
  warning: <WhIconAntdExclamationTriangleFill />,
}

export const Confirm = ({
  variant = 'default',
  title,
  children,
  cancelButtonProps,
  submitButtonProps,
}: TConfirmProps) => {
  const jsxCancelButton = (
    <ButtonCancel {...cancelButtonProps}>
      {cancelButtonProps?.children ?? <T _str="Cancel" />}
    </ButtonCancel>
  )
  const jsxSubmitButton = (
    <ButtonSubmit {...submitButtonProps} danger={variant === 'danger'}>
      {submitButtonProps?.children ?? <T _str="Confirm" />}
    </ButtonSubmit>
  )

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 'calc(32px + 16px)' }}>
        {React.cloneElement(iconMap[variant], {
          style: { color: colorMap[variant], fontSize: '32px' },
        })}
      </div>
      <div style={{ width: 'calc(100% - 32px - 16px)' }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <div style={{ minHeight: 40 }}>
            <Typography variant="body-14" ellipsis={false}>
              {title}
            </Typography>
          </div>
          {typeof children === 'function' ? (
            children({
              jsxCancelButton,
              jsxSubmitButton,
            })
          ) : (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
              }}
            >
              {children}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  columnGap: 8,
                  marginTop: 24,
                }}
              >
                {jsxCancelButton}
                {jsxSubmitButton}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
