import { pathJoin } from '../../../../utils'

export const createDetailViewRoutesMap = (prefix: string) => ({
  overview: pathJoin(prefix)('overview').chain,
  tickets: pathJoin(prefix)('tickets').chain,
  runs: pathJoin(prefix)('runs').chain,
  'available-auto-complete-containers': pathJoin(prefix)(
    'available-auto-complete-containers'
  ).chain,
  exports: pathJoin(prefix)('exports').chain,
})
