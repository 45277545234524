import { pathJoin } from '../../../../utils'
import { createGeneralViewRoutesMap } from './general'
import { createCRMRoutesMap } from './crm'
import { createFleetManagementRoutesMap } from './fleet-management'
import { createSubscriptionRoutesMap } from './billing'
import { createTicketsRoutesMap } from './tickets'
import { createUserMangementRoutesMap } from './user-management'
import { createOperationManagementRoutesMap } from './operation-management'
import { createAssetManagementRoutesMap } from './asset-management'

export const createCompanyRoutesMap = (prefix: string) => ({
  general: pathJoin(prefix)('general').chainMapAuto(createGeneralViewRoutesMap),
  crm: pathJoin(prefix)('crm').chainMapAuto(createCRMRoutesMap),
  'fleet-management': pathJoin(prefix)('fleet-management').chainMapAuto(
    createFleetManagementRoutesMap
  ),
  billing: pathJoin(prefix)('billing').chainMapAuto(
    createSubscriptionRoutesMap
  ),
  tickets: pathJoin(prefix)('tickets').chainMapAuto(createTicketsRoutesMap),
  'user-management': pathJoin(prefix)('user-management').chainMapAuto(
    createUserMangementRoutesMap
  ),
  'operation-management': pathJoin(prefix)('operation-management').chainMapAuto(
    createOperationManagementRoutesMap
  ),
  'alert-rules': pathJoin(prefix)('alert-rules').chainMapAuto((prefix2) => ({
    ':id': (id = ':id') => pathJoin(prefix2)(id).chain,
  })),
  documents: pathJoin(prefix).chain,
  'asset-management': pathJoin(prefix)('asset-management').chainMapAuto(
    createAssetManagementRoutesMap
  ),
})
