import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TDriverStatus = `working` | `not-working` | `unknown`

export const driverStatusLabelMap: Record<TDriverStatus, ReactNode> = {
  working: <T _str="Working" />,
  'not-working': <T _str="Not working" />,
  unknown: <T _str="Unknown" />,
}

export const driverStatusColorMap: Record<TDriverStatus, string> = {
  working: 'blue',
  'not-working': 'red',
  unknown: 'default',
}
