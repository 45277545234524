import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdHardPlasticFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 8 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.85625 4.56125L4.64238 2.86532V0.505096L2.5421 0.5V2.86532L0.97746 4.65807C0.483536 5.19821 0.500549 5.79672 0.500549 6.08486C0.500549 6.102 0.5 6.11729 0.5 6.13489V12.9812C0.5 13.2679 0.791964 13.5 1.15198 13.5H6.84802C7.20804 13.5 7.5 13.2675 7.5 12.9812V6.11775C7.5 6.09644 7.5 6.07606 7.5 6.05429C7.5 5.69898 7.45719 5.03469 6.85625 4.56125ZM6.5116 8.85784C6.5116 9.08436 6.29208 9.26966 6.02372 9.26966H6.01988C5.75151 9.26966 5.53199 9.08436 5.53199 8.85784V6.13628C5.53199 5.90976 5.75151 5.72446 6.01988 5.72446H6.02372C6.29208 5.72446 6.5116 5.90976 6.5116 6.13628V8.85784Z" />
    </svg>
  )
})
