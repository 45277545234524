import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { getOptsFromValueMap } from '../../new-components/select/utils'

export type TCrmServiceServiceTypeChoices =
  | 'EXTRA_EMPTYING'
  | 'BULK_WASTE_PICKUP'
  | 'ADD_CONTAINER'
  | 'CHANGE_CONTAINER_PICKUP_SETTING'
  | `CHANGE_CONTAINER_ROUTE_SCHEME`
  | `CHANGE_CONTAINER_COLLECTION_CALENDAR`
  | 'CHANGE_CONTAINER_TYPE'
  | 'REMOVE_CONTAINER'
  | 'NEW_PROPERTY'
  | 'CONTACT_SYNC'
  | 'MISSING_COLLECTION'

export type TCrmServiceServiceTypeChoicesBulkTicketLegacyFlowSupport =
  OmitUnionString<
    TCrmServiceServiceTypeChoices,
    'CHANGE_CONTAINER_ROUTE_SCHEME' | 'CHANGE_CONTAINER_COLLECTION_CALENDAR'
  >

export const crmServiceServiceTypeChoicesForManagedTicketMutation: TCrmServiceServiceTypeChoices[] =
  [
    'ADD_CONTAINER',
    'BULK_WASTE_PICKUP',
    'CHANGE_CONTAINER_PICKUP_SETTING',
    'CHANGE_CONTAINER_COLLECTION_CALENDAR',
    'CHANGE_CONTAINER_ROUTE_SCHEME',
    'CHANGE_CONTAINER_TYPE',
  ]

export type TCrmServiceServiceTypeChoicesFormSingleFlowSupport =
  TCrmServiceServiceTypeChoices

export const crmServiceServiceTypeChoicesLabelMap: Record<
  TCrmServiceServiceTypeChoices,
  ReactNode
> = {
  ADD_CONTAINER: <T _str="Add Container" />,
  BULK_WASTE_PICKUP: <T _str="Bulk Waste Pickup" />,
  CHANGE_CONTAINER_PICKUP_SETTING: <T _str="Change Container Pickup Setting" />,
  CHANGE_CONTAINER_TYPE: <T _str="Change Container Type" />,
  EXTRA_EMPTYING: <T _str="Extra Emptyting" />,
  REMOVE_CONTAINER: <T _str="Remove Container" />,
  CHANGE_CONTAINER_COLLECTION_CALENDAR: (
    <T _str="Change container collection calendar" />
  ),
  CHANGE_CONTAINER_ROUTE_SCHEME: <T _str="Change container route scheme" />,
  CONTACT_SYNC: <T _str="Contact sync" />,
  NEW_PROPERTY: <T _str="New property" />,
  MISSING_COLLECTION: <T _str="Missing collection" />,
}

export const crmServiceServiceTypeChoicesOptions = getOptsFromValueMap(
  crmServiceServiceTypeChoicesLabelMap
)

export const crmServiceServiceTypeRequiringContainerSourceField: TCrmServiceServiceTypeChoices[] =
  ['ADD_CONTAINER', 'CHANGE_CONTAINER_TYPE']
export const crmServiceServiceTypeRequiringContainerDestinationField: TCrmServiceServiceTypeChoices[] =
  ['REMOVE_CONTAINER', 'CHANGE_CONTAINER_TYPE']
