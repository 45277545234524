import React, { FC, ReactNode, CSSProperties } from 'react'
import { T } from '@transifex/react'
import {
  DownSquareFilled,
  DoubleRightOutlined,
  MinusSquareFilled,
  UpSquareFilled,
} from '@ant-design/icons'
import { Space } from 'src/antd'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TicketTicketPriorityChoices = `A_0` | `A_1` | `A_2` | 'A_3' | 'A_4'

export const ticketTicketPriorityChoicesLabelMap: Record<
  TicketTicketPriorityChoices,
  ReactNode
> = {
  A_0: <T _str="None" />,
  A_1: <T _str="Low" />,
  A_2: <T _str="Medium" />,
  A_3: <T _str="High" />,
  A_4: <T _str="Critical" />,
}

export const ticketTicketPriorityChoicesTagColorMap: Record<
  TicketTicketPriorityChoices,
  string
> = {
  A_0: ``,
  A_1: 'blue',
  A_2: 'yellow',
  A_3: 'red',
  A_4: 'red',
}

export const ticketTicketPriorityChoicesColorMap: Record<
  TicketTicketPriorityChoices,
  string
> = {
  A_0: ``,
  A_1: '#91D5FF',
  A_2: '#FFC069',
  A_3: '#F5222D',
  A_4: '#F5222D',
}
/**
 * Use this map when you are sending data to server.
 */
export const ticketTicketPriorityChoicesMutationValueMap: Record<
  TicketTicketPriorityChoices,
  number
> = {
  A_0: 0,
  A_1: 1,
  A_2: 2,
  A_3: 3,
  A_4: 4,
}

export const ticketTicketPriorityChoicesSelectOptions =
  createOptionsFromLabelMap(ticketTicketPriorityChoicesLabelMap)

export const ticketTicketPriorityChoicesIconMap: Record<
  TicketTicketPriorityChoices,
  FC<{ style?: CSSProperties }> | undefined
> = {
  A_1: ({ style }) => (
    <DownSquareFilled
      style={{ color: ticketTicketPriorityChoicesColorMap.A_1, ...style }}
    />
  ),
  A_2: ({ style }) => (
    <MinusSquareFilled
      style={{ color: ticketTicketPriorityChoicesColorMap.A_2, ...style }}
    />
  ),
  A_3: ({ style }) => (
    <UpSquareFilled
      style={{ color: ticketTicketPriorityChoicesColorMap.A_3, ...style }}
    />
  ),
  A_4: ({ style }) => (
    <DoubleRightOutlined
      style={{
        transform: 'rotate(-90deg)',
        color: ticketTicketPriorityChoicesColorMap.A_4,
        ...style,
      }}
    />
  ),
  A_0: undefined,
}

export const getTicketPriorityChoice = (priority: string) => {
  if (
    priority === 'A_1' ||
    priority === 'A_2' ||
    priority === 'A_3' ||
    priority === 'A_4' ||
    priority === 'A_0'
  ) {
    const Icon = ticketTicketPriorityChoicesIconMap[priority]
    return (
      <>
        {Icon ? (
          <Space>
            <Icon />
            {ticketTicketPriorityChoicesLabelMap[priority]}
          </Space>
        ) : (
          <> {ticketTicketPriorityChoicesLabelMap[priority]}</>
        )}
      </>
    )
  }
  return <></>
}
