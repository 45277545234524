import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdGlassFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 8 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.247 8.1102C7.247 4.96584 5.01705 5.22002 4.95703 2.27279V0.5H3.04447V2.27279C2.98369 5.22002 0.753752 4.96584 0.753752 8.1102L0.75 13.5H7.25L7.247 8.1102ZM6.15003 13.2639C5.76212 12.7713 4.94427 12.4313 3.99963 12.4313C3.05498 12.4313 2.23788 12.7718 1.84922 13.2639H1.43054C1.84321 12.6373 2.83888 12.1948 3.99887 12.1948C5.15886 12.1948 6.15454 12.6377 6.56721 13.2639H6.14853H6.15003Z" />
    </svg>
  )
})
