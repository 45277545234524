import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type ScheduleAutomationActionTypes = `automatic` | `manual`

export const scheduleAutomationActionTypesLableMap: Record<
  ScheduleAutomationActionTypes,
  ReactNode
> = {
  automatic: <T _str="Automatic" />,
  manual: <T _str="Manual" />,
}

export const scheduleAutomationActionTypesOptions = createOptionsFromLabelMap(
  scheduleAutomationActionTypesLableMap
)
