import React from 'react'

import styled from 'styled-components'
import { Modal as AntdModal, Space, Spin } from 'antd'
import { IModal } from './types'
import StyledModal, { ModalLoadContainer } from './style'
import { useTxStrings } from '../../transifex'

const StyledAntdModal = styled(AntdModal)`
  ${StyledModal}
`

export type { IModal } from './types'

const Modal = ({
  children,
  visible,
  onClose,
  disableSubmitButton = false,
  onSubmit = () => null,
  variant,
  loading,
  fullscreen,
  width,
  styles,
  ...restProps
}: IModal): JSX.Element => {
  const { txLoading } = useTxStrings()

  return (
    <StyledAntdModal
      $styles={styles}
      $isFullScreen={fullscreen}
      $isWithFooter={!!restProps.footer}
      style={
        fullscreen
          ? {
              top: '0%',
              left: '0%',
              maxWidth: '100%',
              padding: 0,
            }
          : {}
      }
      $variant={variant}
      visible={visible}
      onCancel={onClose}
      onOk={onSubmit}
      okButtonProps={{ disabled: disableSubmitButton }}
      width={fullscreen ? '100%' : width}
      {...restProps}
    >
      {loading ? (
        <>
          <ModalLoadContainer>
            <Space size="middle">
              <Spin size="large" tip={txLoading} />
            </Space>
          </ModalLoadContainer>
        </>
      ) : (
        children
      )}
    </StyledAntdModal>
  )
}

export default Modal
