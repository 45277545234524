import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  getNullableColumn,
  getTagColumn,
  getTagsColumn,
} from 'src/storybook/components/table-kit'
import { Col, Row } from 'src/antd'

export type RouteStatus =
  | 'pending'
  | 'completed'
  | 'working'
  | 'not-working'
  | 'unknown'

const IconLabel: React.FC<{ icon: ReactNode }> = ({ icon, children }) => {
  return (
    <Row align="middle" gutter={4}>
      <Col>{icon}</Col>
      <Col>{children}</Col>
    </Row>
  )
}

export const routeStatusLabelMap: Record<RouteStatus, ReactNode> = {
  pending: <T _str="Pending" />,
  completed: <T _str="Completed" />,
  unknown: <T _str="Unknown" />,
  working: <T _str="Working" />,
  'not-working': <T _str="Not working" />,
}

export const routeStatusIconMap: Record<RouteStatus, ReactNode> = {
  pending: <PauseCircleOutlined />,
  completed: <CheckCircleOutlined />,
  unknown: <QuestionCircleOutlined />,
  working: <PlayCircleOutlined />,
  'not-working': <CloseCircleOutlined />,
}

export const routeStatusLabelWithIconMap: Record<RouteStatus, ReactNode> = {
  pending: (
    <IconLabel icon={routeStatusIconMap.pending}>
      {routeStatusLabelMap.pending}
    </IconLabel>
  ),
  completed: (
    <IconLabel icon={routeStatusIconMap.completed}>
      {routeStatusLabelMap.completed}
    </IconLabel>
  ),
  unknown: (
    <IconLabel icon={routeStatusIconMap.unknown}>
      {routeStatusLabelMap.unknown}
    </IconLabel>
  ),
  working: (
    <IconLabel icon={routeStatusIconMap.working}>
      {routeStatusLabelMap.working}
    </IconLabel>
  ),
  'not-working': (
    <IconLabel icon={routeStatusIconMap['not-working']}>
      {routeStatusLabelMap['not-working']}
    </IconLabel>
  ),
}

export const routeStatusColorMap: Record<RouteStatus, string> = {
  pending: 'orange',
  completed: 'green',
  unknown: 'default',
  working: 'blue',
  'not-working': 'red',
}

export const routeStatusTagColumn = getTagsColumn(
  routeStatusColorMap,
  (val) => routeStatusLabelWithIconMap[val as RouteStatus]
)

export const routeStatusCell = getNullableColumn(
  getTagColumn(
    routeStatusColorMap,
    (val) => routeStatusLabelWithIconMap[val as RouteStatus]
  )
)
