import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
} from '@ant-design/icons'
import { Col, Row } from 'antd'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import React from 'react'
import ControlBarButton from './ControlBarButton'
import {
  TX_BOLD,
  TX_ITALIC,
  TX_STRIKE_THROUGH,
  TX_UNDERLINE,
} from '../../../transifex'

const inlineStyles = [
  {
    style: 'bold',
    icon: <BoldOutlined />,
    tooltip: TX_BOLD,
  },
  {
    style: 'italic',
    icon: <ItalicOutlined />,
    tooltip: TX_ITALIC,
  },
  {
    style: 'strikethrough',
    icon: <StrikethroughOutlined />,
    tooltip: TX_STRIKE_THROUGH,
  },
  {
    style: 'underline',
    icon: <UnderlineOutlined />,
    tooltip: TX_UNDERLINE,
  },
]

type InlineStylesProps = {
  editorState: BraftEditor
  toggleInlineStyle: (style: string) => void
}

const InlineStyle = ({ editorState, toggleInlineStyle }: InlineStylesProps) => {
  return (
    <Row gutter={8}>
      {inlineStyles.map(({ icon, tooltip, style }) => (
        <Col>
          <ControlBarButton
            active={ContentUtils.selectionHasInlineStyle(editorState, style)}
            onClick={() => toggleInlineStyle(style)}
            tooltip={tooltip}
            icon={icon}
          />
        </Col>
      ))}
    </Row>
  )
}

export default InlineStyle
