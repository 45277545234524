import React, { FC } from 'react'
import { Table, TableColumnProps, TableProps } from 'antd'
import {
  useTreeFilterAndOrder,
  UseTreeFilterAndOrderArgs,
} from '../TreeFilterAndOrder'
import { DEFAULT_TABLE_ACTION_COLUMN_WIDTH } from './consts'

type ExtendedProps<Data, Keys, Actions extends string> = {
  on: Record<Actions, (data: Data) => void>
  filterAndOrderColumns: Keys
}

/**
 * @deprecated
 * Use `createTableUIStatefulColumns`
 */
export const createTableUI = <
  // TODO: fix $TSFixMe
  DataCell extends Record<string, $TSFixMe>,
  ColumnKey extends string,
  Actions extends string
>(props: {
  columnsRenderMap: Record<ColumnKey, TableColumnProps<DataCell>>
  actionsRender?: (args: {
    on: ExtendedProps<DataCell, ColumnKey[], Actions>['on']
  }) => TableColumnProps<DataCell>['render']
  tableColumnsTreeFilterAndOrderProps: Omit<
    UseTreeFilterAndOrderArgs<ColumnKey>,
    'columns'
  >
}) => {
  type ColumnKeys = ColumnKey[]
  type TableUIProps = Omit<TableProps<DataCell>, 'columns'> &
    ExtendedProps<DataCell, ColumnKeys, Actions> & {
      actionColumnProps?: Omit<
        TableColumnProps<DataCell>,
        'render' | 'children'
      >
      /** fill the width left by other columns
       * default to true
       */
      fillWidth?: boolean
    }
  const {
    columnsRenderMap,
    actionsRender,
    tableColumnsTreeFilterAndOrderProps,
  } = props

  const TableUI: FC<TableUIProps> = ({
    filterAndOrderColumns,
    on,
    actionColumnProps,
    fillWidth,
    ...rest
  }) => {
    const columns: TableColumnProps<DataCell>[] = [
      ...filterAndOrderColumns.map((key) => ({
        ...columnsRenderMap[key],
        dataIndex: key,
      })),
    ]

    // pushing a column to just take the width for other columns
    if (fillWidth) {
      // for fluid width.
      columns.push({})
    }

    if (actionsRender) {
      columns.push({
        width: DEFAULT_TABLE_ACTION_COLUMN_WIDTH,
        ...actionColumnProps,
        title: ``,
        render: actionsRender?.({ on }),
        fixed: 'right',
      })
    }

    return (
      <Table
        size="small"
        {...rest}
        columns={columns}
        pagination={{
          ...rest.pagination,
          showQuickJumper: true,
        }}
      />
    )
  }

  const renderColumnAllKeys = Object.keys(columnsRenderMap) as ColumnKey[]
  const filterAndOrderCols = renderColumnAllKeys.reduce((acc, key) => {
    const val = columnsRenderMap[key]
    return {
      ...acc,
      [key]: {
        title: val.title,
        key,
      },
    }
  }, {} as UseTreeFilterAndOrderArgs<ColumnKey>['columns'])

  const useColumnsFilter = () => {
    return useTreeFilterAndOrder<ColumnKey>({
      columns: filterAndOrderCols,
      ...tableColumnsTreeFilterAndOrderProps,
    })
  }

  return {
    useColumnsFilter,
    TableUI,
  }
}
