/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react'
import { Select, SelectProps } from 'antd'
import isoCountries from 'i18n-iso-countries'

import { useLocale } from '@transifex/react'

isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))
isoCountries.registerLocale(require('i18n-iso-countries/langs/da.json'))

const useCountrySelectOptions = () => {
  const locale = useLocale()

  const options = Object.entries(isoCountries.getNames(locale || 'en')).map(
    ([key, value]) => ({
      label: value,
      value: key,
    })
  )

  return options
}

export type TSelectCountry = React.FC<
  SelectProps<string> & { disableNative?: boolean }
>

const SelectCountry: TSelectCountry = ({ ...props }) => {
  const options = useCountrySelectOptions()

  return (
    <Select options={options} showSearch optionFilterProp="label" {...props} />
  )
}

export { SelectCountry, useCountrySelectOptions }
