import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TContainerPickupDaysWeekDayNumber = 'all' | 'even' | 'odd'

export const containerPickupDaysWeekDayNumberLabelMap: Record<
  TContainerPickupDaysWeekDayNumber,
  ReactNode
> = {
  all: <T _str="All" />,
  even: <T _str="Even" />,
  odd: <T _str="Odd" />,
}

export const containerPickupDaysWeekDayNumberOptions =
  createOptionsFromLabelMap(containerPickupDaysWeekDayNumberLabelMap)
