import { pathJoin } from '../../../../utils'

export const createAssetManagementRouteMap = (prefix: string) => ({
  'sensor-measurement-types': pathJoin(prefix)(
    'sensor-measurement-types'
  ).chainMapAuto((prefix2) => ({
    ':id': (id = ':id') => pathJoin(prefix2)(id).chain,
  })),
  'container-imports': pathJoin(prefix)('container-imports').chain,
  'container-weight-imports': pathJoin(prefix)('container-weight-imports')
    .chain,
})
