export * from './impersonator'

export function toggleSidebarOpen(isSidebarOpened: $TSFixMe) {
  return { type: 'TOGGLE_SIDEBAR_OPEN', isSidebarOpened }
}

export function setPageTitle(pageTitle: $TSFixMe) {
  return { type: 'SET_PAGE_TITLE', pageTitle }
}

export function setAllContainersQuery(allContainersQuery: $TSFixMe) {
  return { type: 'SET_All_CONTAINERS_QUERY', allContainersQuery }
}

export function setUserEmail(email: string) {
  return { type: 'SET_USER_EMAIL', payload: email }
}

export function setUser(user: $TSFixMe) {
  return { type: 'SET_USER', user }
}

export function setActiveProjects(newActiveProjects: $TSFixMe) {
  return { type: 'SET_ACTIVE_PROJECTS', newActiveProjects }
}

export function setSelectedContainerFromDashboard(container: $TSFixMe) {
  return { type: 'SET_SELECTED_CONTAINER_FROM_DASHBOARD', container }
}

export function setNewCompanySettings(newCompanySettings: $TSFixMe) {
  return { type: 'SET_NEW_COMPANY_SETTINGS', newCompanySettings }
}

export function setUserLayoutConfig(config: $TSFixMe) {
  return { type: 'SET_USER_LAYOUT_CONFIG', config }
}

export function resetStore() {
  return {
    type: 'RESET_STORE',
  }
}

export function initRouting(state: boolean) {
  return {
    type: 'INIT_ROUTING',
    payload: state,
  }
}

export function setRouting(routing: $TSFixMe) {
  return {
    type: `SET_ROUTING`,
    payload: routing,
  }
}
