import React, { FC } from 'react'
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { T } from '@transifex/react'
import { TagOption } from 'src/storybook/components'
import { array } from 'src/utils/array'
import { useRoutingHistory } from 'src/reducers/routing/child-router-factory'
import useDelayQuery from 'src/hooks/useDelayQuery'

import { PopupContentProps } from '..'
import InfoCard from '../components/InfoCard'
import { GET } from './api'
import {
  GetLocationInfoPopover,
  GetLocationInfoPopoverVariables,
} from './__generated__/GetLocationInfoPopover'

const DepotPopup: FC<PopupContentProps> = ({ id }) => {
  const [history, { routingMap }] = useRoutingHistory()
  const { data, loading } = useDelayQuery<
    GetLocationInfoPopover,
    GetLocationInfoPopoverVariables
  >(
    GET,
    {
      variables: {
        id,
      },
    },
    300
  )

  const type = data?.depot?.depotType

  return (
    <InfoCard
      title={data?.depot?.name ?? ''}
      avatarProps={{
        src: data?.depot?.logo,
        shape: 'square',
      }}
      headIcon={<EnvironmentOutlined />}
      headText={<T _str="Locations" />}
      data={array(
        !!data?.depot?.location && {
          icon: (
            <EnvironmentOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />
          ),
          label: data?.depot?.location.name,
        },
        !!data?.depot?.openingTime && {
          icon: (
            <ClockCircleOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />
          ),
          label: `${data.depot.openingTime} - ${data.depot.closingTime}`,
        },
        !!data?.depot?.contactPerson?.name && {
          icon: <UserOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />,
          label: (
            <T
              _str="Contact: {contact}"
              contact={data.depot.contactPerson.name}
            />
          ),
        }
      )}
      tags={array<TagOption | string>(type && type)}
      onView={() => {
        history.push(
          routingMap.app['fleet-management'].locations[':locationId'](id)._
        )
      }}
      loading={loading}
    />
  )
}

export default DepotPopup
