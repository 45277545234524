import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createRootReducer from './reducers'

const logger = (...args: $TSFixMe[]) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    window.console.log(...args)
  }
}

const dispatchLogger = () => (next: $TSFixMe) => (action: $TSFixMe) =>
  next(action)

const crashReporter = () => (next: $TSFixMe) => (action: $TSFixMe) => {
  try {
    return next(action)
  } catch (err) {
    logger('Caught an exception!', err)
    throw err
  }
}

const bindMiddleware = (middleware: $TSFixMe) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    return composeWithDevTools(applyMiddleware(middleware))
  }

  return applyMiddleware(middleware)
}

const store = createStore(
  createRootReducer(),
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  compose(bindMiddleware(dispatchLogger, crashReporter))
)
export default store

export const clientMainState = store
