import React, { useState, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import { useRefCopy } from './useRefCopy'

export const useStateLinkProp = <T>(
  propValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(propValue)

  useEffect(() => {
    setState(propValue)
  }, [propValue])

  return [state, setState]
}

export const useStateLinkPropCompare = <T>(
  propValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(propValue)
  const getState = useRefCopy(state)

  useEffect(() => {
    if (!isEqual({ v: getState() }, { v: propValue })) {
      setState(propValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // getState,
    propValue,
  ])

  return [state, setState]
}
