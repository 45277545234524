import React from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TVehicleStatus = `working` | `off_work` | `unavailable`

export const vehicleStatusLabelMap: Record<TVehicleStatus, React.ReactNode> = {
  working: <T _str="Working" />,
  off_work: <T _str="Off work" />,
  unavailable: <T _str="Unavailable" />,
}

export const vehicleStatusColorMap: Record<TVehicleStatus, string> = {
  working: 'blue',
  off_work: 'yellow',
  unavailable: 'red',
}

export const VEHICLE_STATUS_OPTIONS = createOptionsFromLabelMap(
  vehicleStatusLabelMap
)
