import React, { ReactNode } from 'react'
import {
  TagsMultiple,
  TagsMultipleProps,
  Typography,
} from 'src/storybook/components'
import { ButtonView } from 'src/storybook/components/Buttons'
import { Avatar, AvatarProps, Col, Row, Skeleton, Space } from 'src/antd'

type TInfoCardProps = {
  loading?: boolean
  avatarProps?: AvatarProps
  title: string
  tags?: TagsMultipleProps['tags']
  headIcon?: ReactNode
  headText: ReactNode
  data?: {
    icon: ReactNode
    label: ReactNode
  }[]
  onView?: () => void
}

const InfoCard = ({
  loading,
  avatarProps,
  title,
  headIcon,
  headText,
  tags = [],
  data = [],
  onView,
}: TInfoCardProps) => {
  return (
    <Row style={{ width: 380 }}>
      <Col xs={24}>
        <Row
          align="middle"
          gutter={16}
          style={{
            padding: '1rem',
            background: '#111D2C',
            marginLeft: 0,
            marginRight: 0,
          }}
          wrap={!!loading}
        >
          {loading &&
            Array(2)
              .fill(0)
              .map(() => {
                return (
                  <Col span={24}>
                    <div style={{ padding: `5px 0px` }}>
                      <Skeleton paragraph={false} active />
                    </div>
                  </Col>
                )
              })}
          {!loading && (
            <>
              <Col style={{ paddingLeft: 0 }}>
                <Avatar
                  {...avatarProps}
                  style={{ border: '2px solid rgba(255, 255, 255, 0.2)' }}
                  size={80}
                >
                  {title.charAt(0)}
                </Avatar>
              </Col>
              <Col>
                <Row gutter={[0, 4]}>
                  <Col xs={24}>
                    <Space
                      style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                      size={4}
                    >
                      {headIcon && headIcon}
                      <Typography style={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                        {headText}
                      </Typography>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Typography
                      variant="subHeader-16"
                      weight="semi-bold"
                      style={{ color: 'white' }}
                    >
                      {title}
                    </Typography>
                  </Col>
                  {tags.length !== 0 && (
                    <Col style={{ paddingTop: 4 }} xs={24}>
                      <TagsMultiple maxTagsToShow={2} tags={tags} />
                    </Col>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Col>
      <Col xs={24}>
        <Row style={{ padding: '1rem' }}>
          {loading && (
            <Col span={24}>
              <div style={{ padding: `5px 0px` }}>
                <Skeleton paragraph={false} active />
              </div>
            </Col>
          )}
          {!loading && (
            <>
              {data.length !== 0 && (
                <Col xs={24}>
                  <Row gutter={[0, 8]}>
                    {data.map((d) => (
                      <Col xs={24}>
                        <Row gutter={8}>
                          <Col>{d.icon}</Col>
                          <Col>{d.label}</Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              )}
              {onView && (
                <Col xs={24}>
                  <Space
                    style={{
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonView onClick={onView} />
                  </Space>
                </Col>
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default InfoCard
