import React, { FC } from 'react'
import { Dropdown, Menu, DropDownProps, MenuProps, MenuItemProps } from 'antd'

import { DropdownSimpleToggler, TDropdownSimpleTogglerProps } from './togglers'

export type TDropdownSimpleProps = {
  items: MenuItemProps[]
  dropdownProps?: Omit<DropDownProps, 'overlay'>
  menuProps?: MenuProps
  menuItemProps?: MenuItemProps
}

export type TDropdownSimpleComponentsProps = {
  TDropdownSimpleTogglerProps: TDropdownSimpleTogglerProps
}

type DropdownSimpleWithTogglers = FC<TDropdownSimpleProps> & {
  DropdownSimpleToggler: typeof DropdownSimpleToggler
}

const DropdownSimple: DropdownSimpleWithTogglers = ({
  children,
  items,
  dropdownProps = {},
  menuItemProps = {},
  menuProps = {},
}) => (
  <Dropdown
    className="wh-styled-simple-dropdown"
    {...dropdownProps}
    overlay={
      <Menu {...menuProps}>
        {React.Children.toArray(
          items.map((p) => <Menu.Item {...menuItemProps} {...p} />)
        )}
      </Menu>
    }
  >
    {children}
  </Dropdown>
)

DropdownSimple.DropdownSimpleToggler = DropdownSimpleToggler

export { DropdownSimple }
