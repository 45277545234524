import React from 'react'
import { CaretDownOutlined } from '@ant-design/icons'
import { Col, Row, Typography, Tag } from 'antd'
import { TSelectFilledProps, SelectFilled } from '../SelectFilled'
import { statusTypeData, TStatusType } from './data'
import { TX_STATUS } from '../../transifex'

export type TSelectFilledStatusProps = Omit<
  TSelectFilledProps,
  'options' | 'suffixIcon'
> & {
  omitOptions?: TStatusType[]
}

export const SelectFilledStatus = ({
  style,
  omitOptions,
  ...props
}: TSelectFilledStatusProps) => {
  return (
    <SelectFilled
      style={{ minWidth: 146, ...style }}
      showSearch={false}
      placeholder={TX_STATUS}
      options={Object.keys(statusTypeData)
        .filter((key) => !omitOptions?.includes(key as TStatusType))
        .map((key) => {
          const statusTypeDataObject = statusTypeData[key as TStatusType]
          return {
            value: key,
            colors: statusTypeDataObject.colors,
            optLabel: (
              <Typography
                style={{
                  color: statusTypeDataObject.colors.primary,
                  fontWeight: 600,
                }}
              >
                {statusTypeDataObject.label}
              </Typography>
            ),
            label: (
              <Row style={{ padding: '0.25rem', paddingLeft: 0 }}>
                <Col xs={24}>
                  <Tag color={statusTypeDataObject.colors.secondary}>
                    <Typography
                      style={{
                        color: statusTypeDataObject.colors.primary,
                      }}
                    >
                      {statusTypeDataObject.label}
                    </Typography>
                  </Tag>
                </Col>
              </Row>
            ),
          }
        })}
      suffixIcon={(colors) => (
        <CaretDownOutlined
          style={{
            fontSize: 16,
            marginRight: 10,
            color: colors?.primary ?? 'lightgray',
            pointerEvents: 'none',
          }}
        />
      )}
      {...props}
    />
  )
}

export default SelectFilledStatus
