import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { getTagsColumn } from 'src/storybook/components/table-kit'

export type TDriverRouteStatus = 'on-route' | 'off-route'

export const driverRouteStatusLabelMap: Record<TDriverRouteStatus, ReactNode> =
  {
    'on-route': <T _str="On route" />,
    'off-route': <T _str="Off route" />,
  }

export const driverRouteStatusColorMap: Record<TDriverRouteStatus, string> = {
  'on-route': 'green',
  'off-route': 'red',
}

export const driverRouteStatusTagColumn = getTagsColumn(
  driverRouteStatusColorMap,
  (val) => driverRouteStatusLabelMap[val as TDriverRouteStatus]
)
