import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'
import { TCompanyMetaValueDisplayOn } from './CompanyMetaValueDisplayOn'

export type TMetaValuesInputTypes =
  | `SELECT`
  | `MULTI_SELECT`
  | `TEXT_AREA`
  | `NUMBER`

export type TMetaValuesEntityTypes = `PROPERTY` | `PROPERTY_GROUP` | `CONTAINER`

export const metaValuesInputTypesLabelMap: Record<
  TMetaValuesInputTypes,
  ReactNode
> = {
  SELECT: <T _str="Selection" />,
  MULTI_SELECT: <T _str="Multi Selection" />,
  NUMBER: <T _str="Number" />,
  TEXT_AREA: <T _str="Text Area" />,
}

export const metaValuesEntityTypesLabelMap: Record<
  TMetaValuesEntityTypes,
  ReactNode
> = {
  PROPERTY: <T _str="Property" />,
  PROPERTY_GROUP: <T _str="Property Group" />,
  CONTAINER: <T _str="Container" />,
}

export const metaValuesEntityTypesDisplayOnMap: Record<
  TMetaValuesEntityTypes,
  TCompanyMetaValueDisplayOn[]
> = {
  PROPERTY: ['PROPERTY_CARD', 'PROPERTY_TABLE', 'NAVIGATION_APP'],
  PROPERTY_GROUP: ['NAVIGATION_APP'],
  CONTAINER: ['AGREEMENT_TABLE', 'CONTAINER_TABLE', 'NAVIGATION_APP'],
}

export const metaValuesOptionsEnabled: Record<TMetaValuesInputTypes, boolean> =
  {
    SELECT: true,
    MULTI_SELECT: true,
    NUMBER: false,
    TEXT_AREA: false,
  }

export const metaValuesInputTypeOptions =
  createOptionsFromLabelMap<TMetaValuesInputTypes>(metaValuesInputTypesLabelMap)
export const metaValuesEntityTypeOptions =
  createOptionsFromLabelMap<TMetaValuesEntityTypes>(
    metaValuesEntityTypesLabelMap
  )
