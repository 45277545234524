import { pathJoin } from '../../../../utils'

export const createTicketsRoutesMap = (prefix: string) => ({
  'ticket-types': pathJoin(prefix)('ticket-types').chain,
  'ticket-category': pathJoin(prefix)('ticket-category').chain,
  'ticket-message-templates': pathJoin(prefix)(
    'ticket-message-templates'
  ).chainMapAuto((prefix2) => ({
    ':id': (id = ':id') => pathJoin(prefix2)(id).chain,
  })),
})
