import React from 'react'
import { T } from '@transifex/react'
import { pathJoin } from '../../../../utils'
import { createRouteMapContainerGroupDetail } from './container-group-detail'

export const createRoutesMapContainerGroup = (prefix: string) => ({
  create: pathJoin(prefix)(`create`).chainWithTitle({
    title: <T _str="New Container Group" />,
    breadCrumbTitle: <T _str="New" />,
  }),
  '/:id': (id: string) =>
    pathJoin(prefix)(id).chainMapAuto(createRouteMapContainerGroupDetail, {
      title: <T _str="Container Group Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
  'edit/:id': (id: string) =>
    pathJoin(prefix)('edit')(id).chainWithTitle({
      title: <T _str="Edit Container Group" />,
      breadCrumbTitle: <T _str="Edit" />,
    }),
})
