import React, { FC, ReactNode } from 'react'
import { Button, ButtonProps, Menu, Dropdown, DropdownProps } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

type TActionButtonActionArgsProps = {
  danger?: boolean
  disabled?: boolean
  cond?: (d?: $TSFixMe) => boolean
}

export type MenuAction = [
  label: ReactNode,
  action: (d?: $TSFixMe) => void,
  args?: TActionButtonActionArgsProps
]

export type MenuActions =
  | MenuAction
  | [
      label: ReactNode,
      childs: MenuAction[],
      args?: TActionButtonActionArgsProps
    ]

const isAction = (a: MenuActions): a is MenuAction => {
  return typeof a[1] === 'function'
}

export type TActionButtonsType = {
  actions: MenuActions[]
  data?: $TSFixMe
  dropdownOnly?: boolean
  dropdownProps?: DropdownProps
  size?: ButtonProps['size']
}

export const ActionButtons: FC<TActionButtonsType> = ({
  actions: [firstAction, ...actionsArray],
  data,
  size = 'middle',
  dropdownOnly = false,
  dropdownProps,
}) => {
  const restArray = dropdownOnly ? [firstAction, ...actionsArray] : actionsArray

  const filteredArray = restArray.filter((a) =>
    !a[2]?.cond ? true : a[2]?.cond?.(data)
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {!dropdownOnly && firstAction && (
        <Button
          danger={firstAction?.[2]?.danger}
          onClick={() => (firstAction as MenuAction)?.[1](data)}
          size={size}
          disabled={firstAction?.[2]?.disabled}
        >
          {firstAction?.[0]}
        </Button>
      )}
      {!!filteredArray.length && (
        <Dropdown
          {...dropdownProps}
          trigger={['hover']}
          overlay={
            <Menu>
              {filteredArray?.map((action, index) => (
                <>
                  {isAction(action) ? (
                    <Menu.Item
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      onClick={() => action?.[1](data)}
                      danger={action?.[2]?.danger}
                      disabled={action?.[2]?.disabled}
                    >
                      <div
                        style={{
                          minWidth: '100px',
                          width: 'auto',
                        }}
                      >
                        {action?.[0]}
                      </div>
                    </Menu.Item>
                  ) : (
                    <Menu.ItemGroup title={action[0]}>
                      {action[1].map((act, subIndex) => (
                        <Menu.Item
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={`${index}_${subIndex}`}
                          onClick={() => act?.[1](data)}
                          danger={act?.[2]?.danger}
                          disabled={act?.[2]?.disabled}
                        >
                          <div
                            style={{
                              minWidth: '100px',
                              width: 'auto',
                            }}
                          >
                            {act?.[0]}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.ItemGroup>
                  )}
                </>
              ))}
            </Menu>
          }
        >
          <Button size={size} type="text">
            <MoreOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  )
}
