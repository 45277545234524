import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import {
  AlertOutlined,
  ApartmentOutlined,
  BlockOutlined,
  BugOutlined,
  BulbOutlined,
  CameraOutlined,
  CarOutlined,
  CarryOutOutlined,
  ClearOutlined,
  ContainerOutlined,
} from '@ant-design/icons'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TMetaValuesIcons =
  | `alert`
  | `block`
  | `bulb`
  | `bug`
  | `car`
  | `camera`
  | `component`
  | `clean`
  | `date`
  | `container`

export const metaValuesIconsLabelMap: Record<TMetaValuesIcons, ReactNode> = {
  alert: <T _str="Alert" />,
  block: <T _str="Block" />,
  bulb: <T _str="Bulb" />,
  bug: <T _str="Bug" />,
  car: <T _str="Car" />,
  camera: <T _str="Camera" />,
  component: <T _str="Component" />,
  clean: <T _str="Clean" />,
  date: <T _str="Date" />,
  container: <T _str="Container" />,
}

export const metaValuesIconsIconMap: Record<TMetaValuesIcons, ReactNode> = {
  alert: <AlertOutlined />,
  block: <BlockOutlined />,
  bulb: <BulbOutlined />,
  bug: <BugOutlined />,
  car: <CarOutlined />,
  camera: <CameraOutlined />,
  component: <ApartmentOutlined />,
  clean: <ClearOutlined />,
  date: <CarryOutOutlined />,
  container: <ContainerOutlined />,
}

export const metaValuesIconTypeOptions =
  createOptionsFromLabelMap<TMetaValuesIcons>(metaValuesIconsLabelMap)
