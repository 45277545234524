import { History } from 'history'
import queryString from 'query-string'
import { routingMap } from '../reducers/routing/routing-map'
import { clientMainState } from '../store'

export const checkForRedirects = (history: History) => {
  const clientState: $TSFixMe = clientMainState.getState()
  const authorized = clientState.settings?.user?.id
  const qs = queryString.parse(history.location.search)
  // redirection otherwise just return false
  if (qs?.company_email_account === 'true' && authorized) {
    // Company email account redirect is being used.
    // keep the query string and redirect more.
    history.replace({
      pathname: routingMap.app.settings['company/:id'](
        clientState.settings?.user.company.id
      ).general['email-accounts']._,
      search: history.location.search,
    })
  }
  return false
}
