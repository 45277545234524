import React from 'react'
import { createAntdIcon } from '../createIcon'

export const WhIconAntdResidualFilled = createAntdIcon(() => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.33066 4.55804L9.67217 2.80954C9.67217 2.80954 8.83488 3.11851 8.40809 2.54237C8.18075 2.23553 8.01212 1.96307 7.88653 1.78901C7.48126 1.22611 7.0632 1.80065 7.0632 1.80065L6.15497 3.93006L5.8532 0.913971C5.2607 2.50852 2.76685 1.22928 3.27272 2.21278L4.67401 4.55804H7.33066Z" />
      <path d="M11.3444 14.6374C11.3217 14.6141 11.1775 14.4602 11.0322 14.1867C10.8763 13.893 10.7269 13.4545 10.7164 12.8804C10.7153 12.854 10.7095 12.7339 10.7112 12.6979C10.7159 12.5762 10.7199 12.4561 10.7234 12.3376C10.7833 10.859 10.7566 8.2079 9.91171 6.77154C9.28607 5.70763 8.04467 5.03204 7.33181 4.82889H4.674C3.9594 5.03521 2.70753 5.71239 2.08422 6.77154C1.23937 8.2079 1.21262 10.859 1.27251 12.3376L1.28298 12.6C1.2853 12.6699 1.2853 12.7371 1.28356 12.8037C1.28356 12.8286 1.28239 12.8529 1.28123 12.8773C1.24867 13.9846 0.690476 14.684 0.641634 14.7432C0.63989 14.7459 0.628261 14.7628 0.628261 14.7628C0.601514 14.7961 0.585815 14.8358 0.585815 14.8792C0.585815 14.9934 0.694546 15.086 0.828862 15.086C0.867819 15.086 0.906776 15.0776 0.946315 15.0649C1.14517 15.0109 2.13596 14.7533 3.20351 14.7533C3.81578 14.7533 4.15942 14.8136 4.49142 14.8718C4.57922 14.8871 5.17056 14.9913 5.7305 14.9913C6.42184 14.9913 7.1161 14.9501 8.00572 14.4612C8.34935 14.5887 8.70869 14.6855 9.05756 14.7591C9.68844 14.8919 10.2827 14.949 10.6827 14.9728C10.9688 14.9897 11.1554 14.9903 11.1816 14.9897H11.1845C11.3113 14.9897 11.4142 14.8977 11.4142 14.7845C11.4142 14.7268 11.3851 14.6818 11.3444 14.6374Z" />
    </svg>
  )
})
