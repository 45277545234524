import { pathJoin } from '../../../../utils'

export const createDetailViewRouteMap = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chain,
  'collection-log': pathJoin(prefix)('collection-log').chain,
  'event-log': pathJoin(prefix)('event-log').chain,
  tickets: pathJoin(prefix)('tickets').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  overview: pathJoin(prefix)('overview').chain,
})
