import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import {
  Dropdown,
  Slider,
  Row,
  Col,
  InputNumber,
  InputNumberProps,
  Divider,
  Button,
  Menu,
  Space,
} from 'antd'
import { SliderRangeProps, SliderSingleProps } from 'antd/es/slider'

import { FromTo } from '../FromTo'
import { TX_APPLY, TX_CANCEL, TX_RESET } from '../../transifex'

type BaseProps<Value> = {
  children: (value: Value, open: () => void) => ReactNode
  addonPre: (value: Value) => ReactNode
  addonSuffix: (value: Value) => ReactNode
}

export type TInputSliderDropdownProps =
  | (SliderRangeProps &
      BaseProps<SliderRangeProps['value']> & {
        fromToInputProps?: Omit<InputNumberProps, 'value' | 'onChange'>
      })
  | (SliderSingleProps &
      BaseProps<SliderSingleProps['value']> & { fromToInputProps?: never })

export const InputSliderDropdown = ({
  children,
  value,
  addonPre,
  addonSuffix,
  range,
  onChange,
  fromToInputProps,
  min = 0,
  max = 100,
  ...rest
}: TInputSliderDropdownProps) => {
  const [visisble, setVisible] = useState(false)
  const [internalValue, setInternalValue] = useState<$TSFixMe>(
    range ? [min, max] : min
  )

  const updateInternalStateWithProp = useCallback(
    (v) => {
      if (v) {
        setInternalValue(v)
      } else {
        setInternalValue(range ? [min, max] : min)
      }
    },
    [max, min, range]
  )

  const onCancel = useCallback(() => {
    updateInternalStateWithProp(value)
    setVisible(false)
  }, [updateInternalStateWithProp, value])

  const onReset = useCallback(() => {
    updateInternalStateWithProp(value)
  }, [updateInternalStateWithProp, value])

  const onApply = useCallback(() => {
    onChange?.(internalValue)
    setVisible(false)
  }, [internalValue, onChange])

  useEffect(() => {
    updateInternalStateWithProp(value)
  }, [updateInternalStateWithProp, value])

  const sliderProps = {
    ...rest,
    value: internalValue,
    range,
    min,
    max,
  }

  const overlay = (
    <div
      style={{
        background: 'white',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 16,
      }}
    >
      <Row gutter={8} style={{ width: '100%' }} wrap={false} align="middle">
        <Col flex="none">{addonPre?.(internalValue as $TSFixMe)}</Col>
        <Col flex="auto">
          <Slider
            {...(sliderProps as SliderSingleProps | SliderRangeProps)}
            onChange={(v: $TSFixMe) => {
              setInternalValue(v)
            }}
            value={internalValue}
          />
        </Col>
        <Col flex="none">{addonSuffix?.(internalValue as $TSFixMe)}</Col>
      </Row>
      {range && (
        <FromTo
          from={
            <InputNumber
              {...fromToInputProps}
              onChange={(v) => {
                if (Number.isFinite(v))
                  setInternalValue((p: $TSFixMe) => [v, p[1]])
              }}
              value={internalValue?.[0]}
              min={min}
              max={internalValue?.[1]}
            />
          }
          to={
            <InputNumber
              {...fromToInputProps}
              value={internalValue?.[1]}
              onChange={(v) => {
                if (Number.isFinite(v))
                  setInternalValue((p: $TSFixMe) => [p[0], v])
              }}
              min={internalValue?.[0]}
              max={max}
            />
          }
        />
      )}
      <Divider style={{ margin: 0 }} />
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Button onClick={onReset} type="text">
          {TX_RESET}
        </Button>
        <Space>
          <Button onClick={onCancel}>{TX_CANCEL}</Button>
          <Button type="primary" onClick={onApply}>
            {TX_APPLY}
          </Button>
        </Space>
      </Space>
    </div>
  )

  return (
    <Dropdown
      visible={visisble}
      trigger={['click']}
      overlay={<Menu>{overlay}</Menu>}
      onVisibleChange={(v) => {
        setVisible(v)
      }}
    >
      {children?.(internalValue as $TSFixMe, () => setVisible(true))}
    </Dropdown>
  )
}
