import createStore from 'zustand'
import { gql, useQuery } from '@apollo/client'
import { FC } from 'react'

type TDefaultConfigStore = {
  defaultLocation: {
    latitude: number
    longitude: number
    name: string
    placeId: string
  }
  loading?: boolean
}

const GET = gql`
  query GetProjectConfigStore {
    me {
      company {
        id
        dashboardLocation {
          id
          latitude
          longitude
          name
          placeId
        }
      }
    }
  }
`

export const useDefaultConfigStore = createStore<TDefaultConfigStore>(() => ({
  defaultLocation: { latitude: 0, longitude: 0, name: '', placeId: '' },
}))

export const DefaultConfigInit: FC = () => {
  useQuery(GET, {
    onCompleted: (data: $TSFixMe) => {
      if (data?.me)
        useDefaultConfigStore.setState({
          defaultLocation: data?.me?.company?.dashboardLocation,
        })
    },
  })

  return null
}
